import { Flex } from '@chakra-ui/react';
import { Content } from 'components/content';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchInput } from 'uikit/search-input';
import { CategoriesSlider } from '../casino/components/categories-slider';
import { SearchContent } from './components/search-content';

export const Search = ({ onClose }) => {
  const [search, setSearch] = useState('');
  const { t } = useTranslation();

  return (
    <Content p="5" maxW="6xl" mx="auto" w="full">
      <Flex flexDirection="column" gap="5">
        <SearchInput
          value={search}
          onChange={setSearch}
          placeholder={t('casino.search.placeholder')}
          autoFocus
        />
        <CategoriesSlider />
        <SearchContent search={search} setSearch={setSearch} />
      </Flex>
    </Content>
  );
};
