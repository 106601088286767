import { Box, Button, Flex } from '@chakra-ui/react';
import { ROULETTE_HANDLER_ID } from 'constants/general';
import { useToastError } from 'hooks/use-toast-error';
import { getHandlerSettings } from 'modules/handler';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GameLayout } from 'services/games/layout/game-layout';
import {
  addRoll,
  getRouletteGame,
  loadGame,
  loadPreviousRolls,
  placeBet as placeWager,
} from 'services/games/modules/roulette';
import { getUser } from 'services/user/modules/user';
import { useActions } from 'store';
import { Bet } from './components/bet';
import { CircleSpinner } from './components/circle-spinner';
import { HistoryBar } from './components/history-bar';
import { PlayerLists } from './components/player-lists';
import { RouletteHistory } from './components/roulette-history';
import { Timer } from './components/timer';

const getHowlerSong = async () => {
  const { Howler, Howl } = await import('howler');

  Howler.volume(0.5);

  const song = new Howl({
    src: ['/assets/audio/rising.wav'],
  });

  return song;
};

export const Roulette = () => {
  const [animatingGameId, setAnimatingGameId] = useState(null);
  // const isLoaded = useSelector(getRouletteIsLoaded);
  const { users = [], data: game } = useSelector(getRouletteGame);
  const user = useSelector(getUser);
  const settings = useSelector(getHandlerSettings(ROULETTE_HANDLER_ID));
  const toastError = useToastError();

  const actions = useActions({
    loadPreviousRolls,
    loadGame,
    addRoll,
    placeBet: placeWager,
  });

  const startAnimation = useCallback(() => {
    if (game?.complete && animatingGameId !== game.gameId) {
      getHowlerSong().then(song => song.play());
      setAnimatingGameId(game.gameId);
    }
  }, [animatingGameId, game]);

  const onVisibilityChange = useCallback(() => {
    if (!document.hidden) {
      startAnimation();
    }
  }, [startAnimation]);

  const placeBet = useCallback(
    async (pickedGroup, amount) => {
      try {
        await actions.placeBet({
          gameId: game.gameId,
          pickedGroup,
          total: amount,
          amount,
        });
      } catch (errors) {
        toastError(errors);
      }
    },
    [actions, game?.gameId, toastError],
  );

  const onRollFinished = useCallback(
    ({ gameId, bonusNumber, complete }) => {
      actions.addRoll(
        gameId,
        complete.rollNumber,
        complete.bonusRollNumber === bonusNumber &&
          complete.bonusRollNumber === complete.rollNumber
          ? bonusNumber
          : undefined,
      );
    },
    [actions],
  );

  useEffect(() => {
    actions.loadGame();
    actions.loadPreviousRolls();
  }, [actions]);

  useEffect(() => {
    startAnimation();
  }, [game?.gameId, user?.id, startAnimation]);

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange, false);

    return () =>
      document.removeEventListener(
        'visibilitychange',
        onVisibilityChange,
        false,
      );
  }, [onVisibilityChange]);

  const gameTime = game?.time || 0;
  const time = settings.roundTime + (new Date().getTime() - gameTime);

  return (
    <GameLayout
      hasFullScreen={false}
      gameId="roulette"
      handlerId={ROULETTE_HANDLER_ID}
      historyElement={<RouletteHistory />}
    >
      <Flex p="5" pb="0" w="full" maxW="6xl" mx="auto">
        <Flex
          flexDirection="column"
          minW="0"
          w="full"
          alignItems="center"
          userSelect="none"
          position="relative"
          rounded="xl"
        >
          <Flex
            flexDirection={{ base: 'column-reverse', md: 'row' }}
            bgPos="center"
            // maxH="610px"
            bg="toffee-base"
            border="1px solid"
            borderColor="truffle-border"
            flexGrow="1"
            w="full"
            minW="300px"
            rounded="xl"
          >
            <Flex
              flexDirection="column"
              w={{ base: 'full', md: '300px' }}
              borderColor="truffle-border !important"
              borderTop={{ base: '1px solid', md: 'none' }}
              roundedTopLeft={{ md: 'xl' }}
              borderRight="1px solid"
              bg="toffee-base"
              roundedBottomRight={{ base: 'xl', md: 'none' }}
              roundedBottomLeft="xl"
              minW="300px"
            >
              <Flex
                w="full"
                align="center"
                gap="2"
                p="5"
                borderBottom="1px solid"
                borderColor="truffle-border"
              >
                <Button
                  w="full"
                  colorScheme="custom"
                  border="1px solid"
                  color="sherbet-purple"
                  borderColor="sherbet-purple"
                  rounded="full"
                  bg="none"
                  gap="2"
                  allowPrevent
                  draggable="false"
                  // @ts-ignore
                  state={{
                    disableScroll: true,
                  }}
                >
                  Manual
                </Button>
                <Button
                  cursor="not-allowed"
                  w="full"
                  colorScheme="custom"
                  border="1px solid"
                  color="candy-floss-text"
                  borderColor="truffle-border"
                  rounded="full"
                  _hover={{ borderColor: 'truffle-border-hover' }}
                  bg="none"
                  gap="2"
                  allowPrevent
                  draggable="false"
                  // @ts-ignore
                  state={{
                    disableScroll: true,
                  }}
                >
                  Auto
                </Button>
              </Flex>
              <Flex
                flexDirection="column"
                justifyContent="space-between"
                gap="5"
                h="full"
              >
                <Box p="5">
                  <Bet
                    placeBetFunc={placeBet}
                    entries={game?.entries}
                    bonusNumber={game?.bonusNumber}
                    settings={settings}
                  />
                </Box>
                <Box borderTop="1px solid" borderColor="truffle-border">
                  <Box
                    // bg="toffee-base"
                    // p="2"
                    maxH="230px"
                    pt="5"
                    px="5"
                    overflow="auto"
                  >
                    <PlayerLists
                      game={game}
                      bonusNumber={game?.bonusNumber}
                      user={user}
                      users={users}
                      entries={game?.entries}
                      settings={settings}
                    />
                  </Box>
                  <Box bg="sugar-dust" rounded="full" mt="5" mb="5" mx="5">
                    <Timer time={time} duration={settings.roundTime} />
                  </Box>
                </Box>
              </Flex>
            </Flex>
            <Flex
              flexDirection="column"
              w="full"
              minH={{ base: '280px', md: '610px' }}
              maxH="610px"
              // justifyContent="center"
              flexGrow="1"
              userSelect="none"
              position="relative"
              overflow="auto"
              m="0 auto"
              roundedTopRight="xl"
            >
              <Flex
                flexDirection="column"
                flexGrow="1"
                w="full"
                p="5"
                pt="0"
                position="relative"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box w="full" mb="7">
                  <CircleSpinner
                    complete={game?.complete}
                    time={gameTime}
                    bonusNumber={game?.bonusNumber}
                    gameId={game?.gameId}
                    settings={settings}
                    spinCount="10"
                    onRollFinished={onRollFinished}
                    animatingGameId={animatingGameId}
                  />
                </Box>
                <Box w="full">
                  <HistoryBar bonusNumber={game?.bonusNumber} />
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </GameLayout>
  );
};
