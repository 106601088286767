import { createReducer } from 'store';
import { loadInactiveQuests, loadQuests } from './actions';
import { QuestType, QuestsType } from './types';

const initialState: QuestsType = {
  quests: [],
  inactiveQuests: [],
  inactiveTotal: 0,
  isInactiveQuestsLoaded: false,
  isLoaded: false,
};

type QuestsResponse = { quests: QuestType[]; totalCount: number };

export const reducer = createReducer(initialState, {
  [loadQuests.toString()]: (
    state,
    { payload }: { payload: QuestsResponse },
  ) => {
    state.quests = payload.quests;
    state.isLoaded = true;
  },
  [loadInactiveQuests.toString()]: (
    state,
    {
      payload,
      meta: { before },
    }: {
      payload: QuestsResponse;
      meta: { before: string };
    },
  ) => {
    if (before) {
      state.inactiveQuests = state.inactiveQuests.concat(payload.quests);
    } else {
      state.inactiveTotal = payload.totalCount;
      state.inactiveQuests = payload.quests;
      state.isInactiveQuestsLoaded = true;
    }
  },
});
