/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-lines */
import {
  Box,
  Button,
  Flex,
  IconButton,
  Skeleton,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import { Content } from 'components/content';
import {
  BonusesIcon,
  ENFlagIcon,
  FriendsIcon,
  NFTIcon,
  PlusIcon,
  RedeemIcon,
  WithdrawIcon,
} from 'icons';
import { MODAL_TYPE, useModal } from 'modals';
import { changeRightSidebarTab, getRightSidebarTab } from 'modules/app';
import {
  getChannelId,
  getChannels,
  getChatNotification,
  getChatNotificationLoaded,
  getIsFriendsLoaded,
  getNewMessages,
  loadChatNotification,
  loadFriends,
} from 'modules/chat';
import { memo, useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useBonuses } from 'services/user/hooks/use-bonuses';
import {
  getUserIsAuthorized,
  getUserIsAuthorizedReady,
} from 'services/user/modules/user';
import { BonusView } from 'services/user/pages/bonuses/components/bonus-view';
import { RakeBackCard } from 'services/user/pages/bonuses/components/bonus-view/components/rakeback-card';
import { WelcomePackage } from 'services/user/pages/bonuses/components/bonus-view/components/welcome-package/welcome-package';
import { RedeemView } from 'services/user/pages/bonuses/components/redeem-view';
import { ProfileVip } from 'services/user/pages/profile/components/profile-vip';
import { NftView } from 'services/user/pages/wallet/components/nft-view';
import { useActions } from 'store';
import { Link } from 'uikit/link';
import { NotificationTip } from 'uikit/notification-tip';
import { Chat } from './components/chat';
import { ChatRoomSwitcher } from './components/chat/components/chat-room-switcher';
import { FriendsList } from './components/friends-list';
import { Wallet } from './components/wallet';

type Props = {
  open?: boolean;
  hasChat?: boolean;
  rightSiderbarActive?: string;
  rightSidebarParams: {
    shortcut?: string;
  };
  setRightSiderbarActive: (s: string) => void;
  toggleRightMenu: () => void;
};

export const RightSidebar: React.FC<Props> = memo(
  ({
    open,
    hasChat = true,
    rightSiderbarActive = '',
    setRightSiderbarActive,
    rightSidebarParams,
  }) => {
    const isAuthorized = useSelector(getUserIsAuthorized);
    const isAuthorizedReady = useSelector(getUserIsAuthorizedReady);
    const location = useLocation();
    const actions = useActions({
      loadFriends,
      loadChatNotification,
      changeRightSidebarTab,
    });
    const channelId = useSelector(getChannelId);
    const isFriendsLoaded = useSelector(getIsFriendsLoaded);
    const channels = useSelector(getChannels);
    const newMessages = useSelector(getNewMessages);

    const {
      t,
      i18n: { language },
    } = useTranslation();
    const prevChannelId = useRef<number | undefined>();
    const {
      isOpen: isOpenChat,
      onToggle,
      onClose: onCloseChat,
    } = useDisclosure();
    const tab = useSelector(getRightSidebarTab);

    const isChat = !rightSiderbarActive && tab === 0;
    const isFriends = !rightSiderbarActive && tab === 1;
    const isBonuses = rightSiderbarActive === 'bonuses';

    const isWallet =
      rightSiderbarActive === 'wallet' || rightSiderbarActive === 'withdrawal';

    const handleToggleChat = () => {
      if (isChat) {
        onToggle();
      }
    };

    const icons = {
      English: ENFlagIcon,
    };

    const { onOpen } = useModal(MODAL_TYPE.AUTH);

    const handleSetTab = useCallback(
      (index: number) => {
        if (!isAuthorized && index === 1) {
          onOpen();
        } else {
          actions.changeRightSidebarTab(index);
        }
      },
      [isAuthorized, onOpen, actions],
    );

    useEffect(() => {
      if (!isFriendsLoaded && isAuthorized && isAuthorizedReady) {
        actions.loadFriends();
      }
    }, [isFriendsLoaded, isAuthorized, actions, isAuthorizedReady]);

    const isSports = location.pathname === '/sports';

    useLayoutEffect(() => {
      if (
        channelId > 0 &&
        prevChannelId.current &&
        prevChannelId.current !== -1
      ) {
        actions.changeRightSidebarTab(0);
        setTimeout(
          () =>
            document
              .querySelector<HTMLInputElement>('.mention-input__input')
              ?.focus(),
          100,
        );
      }
      prevChannelId.current = channelId;
    }, [channelId, actions]);

    const isChatNotificationLoaded = useSelector(getChatNotificationLoaded);

    useEffect(() => {
      if (!isChatNotificationLoaded) {
        actions.loadChatNotification(language);
      }
    }, [actions, language, isChatNotificationLoaded]);

    const chatNotification = useSelector(getChatNotification);

    const ChatFlag = icons[channels[channelId]?.name as keyof typeof icons];

    const btnRef = useRef(null);

    useOutsideClick({
      ref: btnRef,
      enabled: isOpenChat,
      handler: () => setTimeout(() => onCloseChat(), 1),
    });

    const data = useBonuses();

    const welcomeBonus = data.find(
      item => item.bonusType === 'Welcome Deposit Bonus',
    );

    return (
      <Box
        position="relative"
        h="full"
        bg="toffee-base"
        borderLeft="1px"
        borderColor="truffle-border"
      >
        <Flex
          direction="column"
          pb={isSports ? '76px' : undefined}
          h="full"
          overflow="hidden"
        >
          {hasChat && (
            <>
              <Flex
                py="3"
                px="5"
                gap="2"
                borderBottom="1px solid"
                borderColor="truffle-border"
              >
                {rightSiderbarActive ? (
                  <>
                    {['redeem', 'bonuses'].includes(rightSiderbarActive) && (
                      <>
                        <Button
                          aria-label="Bonuses"
                          rounded="full"
                          leftIcon={<BonusesIcon />}
                          onClick={() => {
                            setRightSiderbarActive('bonuses');
                          }}
                          draggable="false"
                          colorScheme="custom"
                          border="1px solid"
                          color={
                            rightSiderbarActive === 'bonuses'
                              ? 'sherbet-purple'
                              : 'candy-floss-text'
                          }
                          borderColor={
                            rightSiderbarActive === 'bonuses'
                              ? 'sherbet-purple'
                              : 'truffle-border'
                          }
                          _hover={
                            rightSiderbarActive === 'bonuses'
                              ? {}
                              : { borderColor: 'truffle-border-hover' }
                          }
                        >
                          Bonuses
                        </Button>
                        <Button
                          aria-label="Bonuses"
                          rounded="full"
                          leftIcon={<RedeemIcon />}
                          onClick={() => {
                            setRightSiderbarActive('redeem');
                          }}
                          draggable="false"
                          colorScheme="custom"
                          border="1px solid"
                          color={
                            rightSiderbarActive === 'redeem'
                              ? 'sherbet-purple'
                              : 'candy-floss-text'
                          }
                          borderColor={
                            rightSiderbarActive === 'redeem'
                              ? 'sherbet-purple'
                              : 'truffle-border'
                          }
                          _hover={
                            rightSiderbarActive === 'redeem'
                              ? {}
                              : { borderColor: 'truffle-border-hover' }
                          }
                        >
                          Redeem
                        </Button>
                      </>
                    )}
                    {(isWallet || rightSiderbarActive === 'nft') && (
                      <Flex align="center" gap="2" overflow="auto" w="full">
                        <Button
                          flexShrink="0"
                          rounded="full"
                          leftIcon={<PlusIcon />}
                          onClick={() => {
                            setRightSiderbarActive('wallet');
                          }}
                          draggable="false"
                          colorScheme="custom"
                          border="1px solid"
                          color={
                            rightSiderbarActive === 'wallet'
                              ? 'sherbet-purple'
                              : 'candy-floss-text'
                          }
                          borderColor={
                            rightSiderbarActive === 'wallet'
                              ? 'sherbet-purple'
                              : 'truffle-border'
                          }
                          _hover={
                            rightSiderbarActive === 'wallet'
                              ? {}
                              : { borderColor: 'truffle-border-hover' }
                          }
                        >
                          {t('wallet.deposit')}
                        </Button>
                        <Button
                          flexShrink="0"
                          leftIcon={<WithdrawIcon />}
                          rounded="full"
                          onClick={() => {
                            setRightSiderbarActive('withdrawal');
                          }}
                          draggable="false"
                          colorScheme="custom"
                          border="1px solid"
                          color={
                            rightSiderbarActive === 'withdrawal'
                              ? 'sherbet-purple'
                              : 'candy-floss-text'
                          }
                          borderColor={
                            rightSiderbarActive === 'withdrawal'
                              ? 'sherbet-purple'
                              : 'truffle-border'
                          }
                          _hover={
                            rightSiderbarActive === 'withdrawal'
                              ? {}
                              : { borderColor: 'truffle-border-hover' }
                          }
                        >
                          {t('wallet.withdraw')}
                        </Button>
                        <IconButton
                          aria-label="NFT"
                          flexShrink="0"
                          icon={<NFTIcon />}
                          rounded="full"
                          onClick={() => {
                            setRightSiderbarActive('nft');
                          }}
                          draggable="false"
                          colorScheme="custom"
                          border="1px solid"
                          color={
                            rightSiderbarActive === 'nft'
                              ? 'sherbet-purple'
                              : 'candy-floss-text'
                          }
                          borderColor={
                            rightSiderbarActive === 'nft'
                              ? 'sherbet-purple'
                              : 'truffle-border'
                          }
                          _hover={
                            rightSiderbarActive === 'nft'
                              ? {}
                              : { borderColor: 'truffle-border-hover' }
                          }
                        />
                      </Flex>
                    )}
                  </>
                ) : (
                  <>
                    <Button
                      flexShrink="0"
                      ref={btnRef}
                      onClick={() => {
                        handleSetTab(0);
                        handleToggleChat();
                      }}
                      leftIcon={ChatFlag && <ChatFlag />}
                      draggable="false"
                      colorScheme="custom"
                      border="1px solid"
                      rounded="full"
                      color={isChat ? 'sherbet-purple' : 'candy-floss-text'}
                      borderColor={isChat ? 'sherbet-purple' : 'truffle-border'}
                      _hover={
                        isChat ? {} : { borderColor: 'truffle-border-hover' }
                      }
                    >
                      {t('nav-bar.chat')}:{' '}
                      {channels[channelId]?.name || (
                        <Skeleton as="span" ml="1" rounded="full">
                          Chat
                        </Skeleton>
                      )}
                      <NotificationTip
                        ml="2"
                        notifications={Object.keys(newMessages).length}
                      />
                    </Button>
                    <Button
                      rounded="full"
                      leftIcon={<FriendsIcon />}
                      onClick={() => {
                        handleSetTab(1);
                        onCloseChat();
                      }}
                      draggable="false"
                      colorScheme="custom"
                      border="1px solid"
                      color={isFriends ? 'sherbet-purple' : 'candy-floss-text'}
                      borderColor={
                        isFriends ? 'sherbet-purple' : 'truffle-border'
                      }
                      _hover={
                        isFriends ? {} : { borderColor: 'truffle-border-hover' }
                      }
                    >
                      {t('right-sidebar.friends')}
                    </Button>
                  </>
                )}
              </Flex>
              {isOpenChat && <ChatRoomSwitcher onClose={onCloseChat} />}
              {isChat && (
                <Content
                  overflow="hidden"
                  flexGrow="1"
                  direction="column"
                  key={
                    prevChannelId.current &&
                    prevChannelId.current > 0 &&
                    prevChannelId.current !== channelId
                      ? channelId
                      : 'loading'
                  }
                >
                  {!!chatNotification && (
                    <Box
                      m="3"
                      rounded="4px"
                      bg="sherbet-purple"
                      p="2"
                      mb="3"
                      fontSize="sm"
                    >
                      {chatNotification.startsWith('http://') ||
                      chatNotification.startsWith('https://') ? (
                        <Link
                          _hover={{ opacity: 0.9, textDecoration: 'underline' }}
                          href={chatNotification}
                        >
                          {chatNotification}
                        </Link>
                      ) : (
                        chatNotification
                      )}
                    </Box>
                  )}
                  <Chat open={open} />
                </Content>
              )}
              {isFriends && (
                <Content overflow="hidden" direction="column" h="full">
                  <FriendsList
                    isFriendsLoaded={isFriendsLoaded}
                    changeTab={handleSetTab}
                  />
                </Content>
              )}
              {isBonuses && (
                <Content overflow="hidden" direction="column" h="full">
                  <Box borderBottom="1px solid" borderColor="truffle-border">
                    <ProfileVip />
                  </Box>
                  <RakeBackCard />
                  <Flex
                    direction="column"
                    gap="5"
                    overflow="auto"
                    pb="5"
                    h="full"
                  >
                    {welcomeBonus && <WelcomePackage bonus={welcomeBonus} />}
                    {/* @ts-ignore */}
                    <BonusView onClose={onCloseChat} />
                  </Flex>
                </Content>
              )}
              {rightSiderbarActive === 'redeem' && <RedeemView />}
              {isWallet && (
                <Content direction="column" h="full" overflow="auto">
                  <Box borderBottom="1px solid" borderColor="truffle-border">
                    <ProfileVip />
                  </Box>
                  <Flex direction="column" h="full" overflow="auto">
                    <Wallet
                      shortcut={rightSidebarParams.shortcut}
                      isWithdrawal={rightSiderbarActive === 'withdrawal'}
                    />
                  </Flex>
                </Content>
              )}
              {rightSiderbarActive === 'nft' && <NftView />}
            </>
          )}
        </Flex>
      </Box>
    );
  },
);
