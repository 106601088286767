export const TOURNAMENT_CONTENT_BY_TYPE = {
  DAILY: {
    title: 'Daily Scramble',
    description: 'tournaments.daily.description',
    image:
      'https://spinkick.imgix.net/tournaments/daily-scramble-2.png?auto=format&w=1000',
    color: '#3E89F9',
    isMultiplier: true,
    isAnimate: false,
    link: '/tournaments/daily-scramble',
  },
  WEEKLY: {
    title: 'Weekly Race',
    description: 'tournaments.weekly.description',
    image:
      'https://spinkick.imgix.net/tournaments/weekly-race-3.png?auto=format&w=1000',
    color: '#3E89F9',
    isMultiplier: false,
    isAnimate: true,
    link: '/tournaments/weekly-race',
  },
};
