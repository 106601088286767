import { Button } from '@chakra-ui/react';
import { BonusPopupContext } from 'contexts/bonus-popup-context';
import { formatCredit } from 'helpers/numeral';
import { useToastError } from 'hooks/use-toast-error';
import { TransactionsIcon } from 'icons';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  activateBonus,
  cancelBonusSession,
  deactivateBonus,
  getUserBonusByType,
  getUserBonusesList,
  getUserCurrentWagerAmountOnBonus,
  getUserLevel,
  loadUser,
  pauseBonusSession,
  resumeBonusSession,
  startBonusSession,
  transferBonus,
} from 'services/user/modules/user';
import { useActions } from 'store';
import { toastError, toastSuccess } from 'toasts';
import { Errors } from 'types';
import { ConfirmButton } from 'uikit/confirm-button';
import { Link } from 'uikit/link';

export const useBonuses = ({ onClose }: { onClose?: () => void } = {}) => {
  const toastErrorCommon = useToastError();
  const level = useSelector(getUserLevel);
  const currentWagerAmountOnBonus = useSelector(
    getUserCurrentWagerAmountOnBonus,
  );
  const bonusesList = useSelector(getUserBonusesList);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<string | number>(-1);
  const { isAvailable } = useContext(BonusPopupContext);

  const hasTimeLimitedBonus = useSelector(
    getUserBonusByType('Welcome Limisted Time Deposit Bonus'),
  );

  const actions = useActions({
    startBonusSession,
    resumeBonusSession,
    pauseBonusSession,
    transferBonus,
    cancelBonusSession,
    activateBonus,
    loadUser,
    deactivateBonus,
  });

  const filteredBonusesList =
    hasTimeLimitedBonus && !isAvailable
      ? bonusesList.filter(
          item => item.bonusType !== 'Welcome Limisted Time Deposit Bonus',
        )
      : bonusesList;

  const data = filteredBonusesList.map((item, index) => {
    let button;

    const cancelAction = async () => {
      setIsLoading(`c-${index}`);
      try {
        await actions.cancelBonusSession({
          bonusBalanceId: item.id,
        });
        await actions.loadUser();
      } catch (errors) {
        toastErrorCommon(errors as Errors);
      } finally {
        setIsLoading(-1);
      }
    };

    const cancelContent = item.isCurrent && (
      <ConfirmButton
        colorScheme="danger"
        confirmText="Confirm"
        isForce
        onClick={cancelAction}
        isLoading={isLoading === `c-${index}`}
      >
        Forfeit
      </ConfirmButton>
    );

    const activateAction = async () => {
      setIsLoading(index);
      try {
        await actions.activateBonus({
          bonusBalanceId: item.id,
          isDepositReload: item.isDepositReload,
        });
        await actions.loadUser();
      } catch (errors) {
        toastErrorCommon(errors as Errors);
      } finally {
        setIsLoading(-1);
      }
    };

    const deactivateAction = async () => {
      setIsLoading(index);
      try {
        await actions.deactivateBonus({
          bonusBalanceId: item.id,
          isDepositReload: item.isDepositReload,
        });
        await actions.loadUser();
      } catch (errors) {
        toastErrorCommon(errors as Errors);
      } finally {
        setIsLoading(-1);
      }
    };

    let startAction;
    let transferAction;

    const activateContent = (
      <Button
        colorScheme="purple"
        onClick={activateAction}
        isLoading={isLoading === index}
      >
        Activate
      </Button>
    );

    const wagerRequirement = item.activatedAmount
      ? `$${formatCredit(currentWagerAmountOnBonus)} / $${formatCredit(
          item.wagerRequirement * item.activatedAmount,
        )}`
      : null;
    const wagerRequirementData = item.activatedAmount
      ? [
          currentWagerAmountOnBonus,
          item.wagerRequirement * item.activatedAmount,
        ]
      : null;

    if (item.status === 0) {
      button = activateContent;
    } else if (item.status === 1) {
      button = (
        <>
          <Button as={Link} to="?right=wallet" colorScheme="purple">
            Deposit
          </Button>
          <Button
            rounded="full"
            onClick={deactivateAction}
            isLoading={isLoading === index}
          >
            {t('bonus.deactivate')}
          </Button>
        </>
      );
    } else if (item.status === 2) {
      button = activateContent;

      if (item.canStart) {
        startAction = async () => {
          setIsLoading(index);
          try {
            await actions.startBonusSession({
              bonusBalanceId: item.id,
            });
            if (item.isWagerRequiredOnMain) {
              toastSuccess({
                title: t('bonus.started-session-real'),
                description: t('bonus.started-session-description-real'),
              });
            } else {
              toastSuccess({
                title: t('bonus.started-session'),
                description: t('bonus.started-session-description'),
              });
            }
          } catch (errors) {
            if (
              (errors as Errors).global === 'BONUS_INSUFFICIENT_WAGER_AMOUNT'
            ) {
              const { requiredwager, currentwager } = errors as {
                requiredwager: number;
                currentwager: number;
              };
              toastError({
                title: t('BONUS_INSUFFICIENT_WAGER_AMOUNT'),
                description: t('BONUS_INSUFFICIENT_WAGER_AMOUNT_DESCRIPTION', {
                  amount: `$${formatCredit(
                    Number(requiredwager - currentwager),
                  )}`,
                }),
              });
            } else {
              toastErrorCommon(errors as Errors);
            }
          } finally {
            setIsLoading(-1);
          }
        };
        button = (
          <Button
            colorScheme="purple"
            onClick={startAction}
            isLoading={isLoading === index}
          >
            {t('bonus.start')}
          </Button>
        );
      }
    } else if (item.isCurrent) {
      button = (
        <Button leftIcon={<TransactionsIcon />} rounded="full">
          {wagerRequirement}
        </Button>
      );
    } else if (item.transferrableAmount > 0) {
      transferAction = async () => {
        setIsLoading(index);
        try {
          await actions.transferBonus({
            bonusBalanceId: item.id,
          });
          await actions.loadUser();
          setIsLoading(-1);
          toastSuccess({
            title: t('bonus.success-transfer'),
            description: t('bonus.success-transfer-description'),
          });
          onClose?.();
        } catch (errors) {
          toastErrorCommon(errors as Errors);
        }
      };
      button = (
        <Button
          colorScheme="purple"
          onClick={transferAction}
          isLoading={isLoading === index}
        >
          {t('bonus.complete-bonus')}
        </Button>
      );
    } else if (item.status === 3) {
      button = cancelContent;
    }

    const action = button;

    let description;
    let minDeposit;
    let maxBonus;

    if (item.bonusType === 'Coupon') {
      description = t('bonus.coupon-received', {
        amount: formatCredit(item.initialAmount),
      });
    } else if (item.bonusType === 'Sunday Deposit Reload') {
      minDeposit = `$${formatCredit(50)}`;
      maxBonus = `$${formatCredit(level >= 4 ? 500 : 150)}`;

      if (!item.initialAmount) {
        description = t('bonus.sundue-received');
      } else {
        description = t('bonus.turned-sundae', {
          amount: formatCredit(item.initialAmount),
        });
      }
    } else if (item.bonusType === 'Weekly Bonus For Top Spenders') {
      if (item.isWagerRequiredOnMain) {
        if (!item.initialAmount) {
          description = t('bonus.weekly.desc', {
            amount: item.reloadPercentage * 100,
          });
          minDeposit = `$${formatCredit(item.reloadMinAmount)}`;
          maxBonus = `$${formatCredit(
            item.reloadMaxAmount * item.reloadPercentage,
          )}`;
        } else {
          description = t('bonus.turned-weekly', {
            amount: formatCredit(item.initialAmount),
          });
        }
      } else {
        description = t('bonus.weekly-cash.desc', {
          amount: formatCredit(item.initialAmount),
        });
      }
    }

    return {
      ...item,
      name: item.name || item.bonusType,
      endTime: Number(item.expiryTime || item.activateExpiryTime) * 1000,
      wager: `${item.wagerRequirement}x`,
      wager2: `${item.wagerRequirement}`,
      description,
      minDeposit,
      maxBonus,
      remainingRedeemedAmount:
        item.remainingRedeemedAmount &&
        `$${formatCredit(item.remainingRedeemedAmount)}`,
      wagerRequirement,
      wagerRequirementData,
      action,
      activatedAmount: item.activatedAmount,
      isDepositReload: item.isDepositReload,
      transferrableAmount: item.transferrableAmount,
      cancelAction,
      cancelContent,
      activateAction,
      deactivateAction,
      startAction,
      transferAction,
    };
  });

  return data;
};
