import { Box, Flex } from '@chakra-ui/react';
import { MODAL_TYPE, useModal } from 'modals';
import { getHandlerSettings } from 'modules/handler';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getRoulettePlaceBetLoading } from 'services/games/modules/roulette';
import {
  getUserBalance,
  getUserId,
  getUserIsAuthorized,
} from 'services/user/modules/user';
import { BetInput } from 'uikit/bet-input';
import { ConfirmButton } from 'uikit/confirm-button';
import { pickedGroupToColor } from '../../utils';

type Props = {
  placeBetFunc: (id: number, amount: number) => void;
  bonusNumber: number;
  entries: {
    userId: string;
    wagerGroup: number;
    amount: number;
  }[];
};

export const Bet: React.FC<Props> = ({
  placeBetFunc,
  bonusNumber,
  entries = [],
}) => {
  const [betAmount, setBetAmount] = useState<number | string>('');

  const { groupMinWager, groupMaxWager, wagerGroupMultipliers, wagerGroups } =
    useSelector(getHandlerSettings(16));
  const isAuthorized = useSelector(getUserIsAuthorized);
  const userId = useSelector(getUserId);
  const userBalance = useSelector(getUserBalance);
  const placeBetLoading = useSelector(getRoulettePlaceBetLoading);
  const { t } = useTranslation();

  // const disabled = !isAuthorized;

  const { onOpen: loginModalOpen } = useModal(MODAL_TYPE.AUTH);

  const [minWager, maxWager] = useMemo(() => {
    const minBet = Math.min(...(groupMinWager as number[]));
    let maxBet = Math.max(...(groupMaxWager as number[]));

    if (isAuthorized) {
      maxBet = Math.min(maxBet, userBalance);
    }

    return [minBet, maxBet];
  }, [isAuthorized, userBalance, groupMinWager, groupMaxWager]);

  const wagerGroupTotals = useMemo(() => {
    if (userId) {
      return entries.reduce(
        (acc, entry) => {
          if (!acc[entry.wagerGroup]) {
            acc[entry.wagerGroup] = 0;
          }
          if (entry.userId === userId) {
            acc[entry.wagerGroup] += entry.amount;
          }
          return acc;
        },
        {} as { [key: number]: number },
      );
    }

    return {};
  }, [userId, entries]);

  const wagerGroupInfos = useMemo(
    () =>
      (wagerGroups as number[]).reduce(
        (acc, id: number) => {
          const wagerGroupTotal = wagerGroupTotals[id] || 0;
          const wagerGroupMax = Math.max(
            0,
            (groupMaxWager as number[])[id] - wagerGroupTotal,
            userBalance,
          );

          return {
            ...acc,
            [id]: {
              max: wagerGroupMax,
            },
          };
        },
        {} as { [id: number]: { max: number } },
      ),
    [wagerGroups, userBalance, groupMaxWager, wagerGroupTotals],
  );

  const handleChangeBetValue = (value: number | string) => {
    if (!value) {
      setBetAmount('');
    } else {
      const amount = Math.max(minWager, Math.min(maxWager, Number(value)));
      setBetAmount(amount);
    }
  };

  const placeBet = (id: number) => () => {
    const amount = Math.min(wagerGroupInfos[id].max, Number(betAmount));
    placeBetFunc(id, amount);
  };

  const wagerGroupButtons = (wagerGroups as number[]).map((groupId: number) => {
    const bg = pickedGroupToColor(groupId, bonusNumber);
    return (
      <ConfirmButton
        rounded="full"
        colorScheme="custom"
        w="full"
        size="md"
        isDisabled={placeBetLoading[groupId] || !wagerGroupInfos[groupId]?.max}
        bg={bg}
        color="vanilla-text"
        _hover={{ bg, opacity: 0.9 }}
        key={groupId}
        isLoading={placeBetLoading[groupId]}
        onClick={isAuthorized ? placeBet(groupId) : loginModalOpen}
      >
        {(wagerGroupMultipliers as number[])[groupId]}x
      </ConfirmButton>
    );
  });

  return (
    <>
      <Box mx="auto">
        <Flex gap="2" mb="5" align="center">
          <BetInput
            value={betAmount}
            onChange={handleChangeBetValue}
            canEmpty
          />
        </Flex>
      </Box>
      <Flex gap="2">
        <>
          <Box flex="1" flexBasis="20%">
            {wagerGroupButtons[0]}
          </Box>
          <Box flex="1" flexBasis="20%">
            {wagerGroupButtons[1]}
          </Box>
          <Box flex="1" flexBasis="20%">
            {wagerGroupButtons[2]}
          </Box>
          <Box flex="1" flexBasis="20%">
            {wagerGroupButtons[3]}
          </Box>
        </>
      </Flex>
    </>
  );
};
