import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import { CheckIcon } from 'icons';
import { useTranslation } from 'react-i18next';
import { VERSUS_COLORS } from 'services/games/pages/versus/constants';

export const ColorSelect = ({ colorIdx, setColorIdx, playerCount }) => {
  const { t } = useTranslation();
  return (
    <Flex flexDirection="column" gap="2" w={{ base: 'full', md: 'auto' }}>
      <Text color="candy-floss-text" fontWeight="500">
        {t('versus.color')}
      </Text>
      <Flex gap="2">
        {VERSUS_COLORS.map((currentColor, i) => (
          <Box key={currentColor}>
            <IconButton
              rounded="full"
              bg={currentColor}
              colorScheme="custom"
              _hover={{ bg: currentColor, opacity: '0.9' }}
              _focus={{ bg: currentColor }}
              icon={
                currentColor === VERSUS_COLORS[colorIdx] ? <CheckIcon /> : null
              }
              aria-label={`Select color ${currentColor}`}
              onClick={() => setColorIdx(i)}
              isDisabled={playerCount <= i}
            />
          </Box>
        ))}
      </Flex>
    </Flex>
  );
};
