import { QuestsType } from './types';

type StateType = {
  quests: QuestsType;
};

export const getQuests = (state: StateType) => state.quests.quests;
export const getInactiveQuests = (state: StateType) =>
  state.quests.inactiveQuests;
export const getInactiveTotal = (state: StateType) =>
  state.quests.inactiveTotal;
export const getQuestsIsLoaded = (state: StateType) => state.quests.isLoaded;
export const getInactiveQuestsIsLoaded = (state: StateType) =>
  state.quests.isInactiveQuestsLoaded;
