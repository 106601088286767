import { Button, Flex, InputGroup } from '@chakra-ui/react';
import { CustomInput } from 'forms/custom-input';
import { toFixedNumber } from 'helpers/numeral';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onChange: (val: string | number) => void;
  value: number | string;
  canEmpty?: boolean;
};

export const BetInput: React.FC<Props> = ({
  onChange,
  value,
  canEmpty = false,
}) => {
  const [val, setVal] = useState('1.00'); // Changed from '' to '0.00'
  const { t } = useTranslation();

  useEffect(() => {
    if (value || !canEmpty) {
      setVal(Number(value).toFixed(2));
    } else {
      setVal('1.00'); // Ensure default value is '0.00' when canEmpty is true and value is falsy
    }
  }, [value, canEmpty]);

  const handlerBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (canEmpty) {
      onChange(e.target.value ? toFixedNumber(e.target.value) : '1.00');
    } else {
      onChange(toFixedNumber(e.target.value));
    }
  };

  const handleX2 = () => {
    onChange(toFixedNumber(Number(value) * 2));
  };

  const handleHalf = () => {
    onChange(toFixedNumber(Number(value) / 2));
  };

  return (
    <Flex w="full" align="end">
      <InputGroup>
        <CustomInput
          label={t('input.bet-amount')}
          w="full"
          borderRight="none"
          roundedRight="none"
          type="number"
          inputMode="decimal"
          placeholder="1.00"
          value={val}
          onChange={e => {
            setVal(e.target.value);
            if (!e.target.value && canEmpty) {
              onChange('');
            }
          }}
          onBlur={handlerBlur}
        />
      </InputGroup>
      <Flex align="center">
        <Button
          bg="none"
          rounded="none"
          border="1px solid"
          borderRight="none"
          borderColor="truffle-border"
          onClick={handleHalf}
        >
          1/2
        </Button>
        <Button
          bg="none"
          border="1px solid"
          borderColor="truffle-border"
          roundedLeft="none"
          roundedRight="xl"
          onClick={handleX2}
        >
          2x
        </Button>
      </Flex>
    </Flex>
  );
};
