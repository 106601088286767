import { Button, Flex } from '@chakra-ui/react';
import { LobbyIcon, OriginalsIcon } from 'icons';
import { getUserCountry } from 'modules/handler';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { getSlotsCategories } from 'services/games/modules/slots';
import { getUserIsAuthorized } from 'services/user/modules/user';

export const CategoriesSlider = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isAuthorized = useSelector(getUserIsAuthorized);
  const navigate = useNavigate();
  const categoriesData = useSelector(getSlotsCategories);

  const containerRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState<number>(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const country = useSelector(getUserCountry);

  useEffect(() => {
    const container = containerRef.current;

    if (!container) {
      return () => {};
    }

    const handleMouseDown = (e: MouseEvent) => {
      setIsDragging(true);
      setStartX(e.pageX - container.offsetLeft);
      setScrollLeft(container.scrollLeft);
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (!isDragging) return;
      const x = e.pageX - container.offsetLeft;
      const walk = x - startX;
      container.scrollLeft = scrollLeft - walk;
    };

    container.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      container.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isDragging, startX, scrollLeft]);

  const items = useMemo(
    () =>
      [
        {
          icon: <LobbyIcon />,
          title: t('nav-bar.lobby'),
          to: '/',
        },
        {
          icon: <OriginalsIcon />,
          title: t('casino.category.originals'),
          to: '/casino/category/originals',
          'aria-label': 'Originals',
        },
        {
          title: t('casino.category.slots'),
          category: 'slots',
        },
        {
          title: t('casino.category.live-casino'),
          category: 'live-casino',
        },
        {
          title: t('casino.category.game-shows'),
          category: 'game-shows',
        },
        {
          title: t('casino.category.new-releases'),
          category: 'new-releases',
        },
        // ...(isAuthorized
        //   ? [
        //       {
        //         title: t('casino.category.favorites'),
        //         to: '/casino/category/favourites',
        //       },
        //       {
        //         title: t('casino.category.recent'),
        //         to: '/casino/category/recent',
        //       },
        //     ]
        //   : []),
        // {
        //   title: t('casino.tags.bonus-buy'),
        //   category: 'bonus-buy',
        // },
        // {
        //   title: t('casino.category.animals'),
        //   category: 'animals',
        // },
        // {
        //   title: t('casino.category.video-bingo'),
        //   category: 'bingo',
        // },
        // {
        //   title: t('casino.category.blackjack'),
        //   category: 'blackjack',
        // },
        // {
        //   title: t('casino.category.cards'),
        //   category: 'cards',
        // },
        // {
        //   title: t('casino.category.casual'),
        //   category: 'casual',
        // },
        // {
        //   title: t('casino.category.crash'),
        //   category: 'crash',
        // },
        // {
        //   title: t('casino.category.dice'),
        //   category: 'dice',
        // },
        // {
        //   title: t('casino.category.fantasy'),
        //   category: 'fantasy',
        // },
        // {
        //   title: t('casino.category.game-shows'),
        //   category: 'game-shows',
        // },
        // {
        //   title: t('casino.category.history'),
        //   category: 'history',
        // },
        // {
        //   title: t('casino.category.horror'),
        //   category: 'horror',
        // },
        // {
        //   title: t('casino.category.lightning'),
        //   category: 'lightning',
        // },
        // {
        //   title: t('casino.category.roulette'),
        //   category: 'roulette',
        // },
        // {
        //   title: t('casino.category.scratch-cards'),
        //   category: 'scratch-cards',
        // },
        // {
        //   title: t('casino.category.3-reels'),
        //   category: '3-reels',
        // },
        // {
        //   title: t('casino.category.wild-west'),
        //   category: 'wild-west',
        // },
        // {
        //   title: t('casino.category.winter'),
        //   category: 'winter',
        // },
      ].filter(({ category }) => {
        if (category) {
          return (
            category === 'new-releases' || categoriesData.includes(category)
          );
        }
        return true;
      }),
    [t, categoriesData, isAuthorized],
  );

  return (
    <Flex
      gap="2"
      p="1"
      m="-1"
      align="center"
      overflow="auto"
      zIndex="2"
      ref={containerRef}
      cursor={isDragging ? 'grabbing' : undefined}
    >
      {items.map(({ icon, title, to, category, ...rest }, index) => {
        const link = to || `/casino/category/${category}`;
        const isActive = link === pathname;
        return (
          <Button
            colorScheme="custom"
            border="1px solid"
            color={isActive ? 'sherbet-purple' : 'candy-floss-text'}
            borderColor={isActive ? 'sherbet-purple' : 'truffle-border'}
            rounded="full"
            _hover={isActive ? {} : { borderColor: 'truffle-border-hover' }}
            bg="toffee-base"
            flexShrink="0"
            gap="2"
            draggable="false"
            as={Link}
            // @ts-ignore
            state={{
              disableScroll: true,
            }}
            to={link}
            cursor={isDragging ? 'grabbing' : undefined}
            key={index}
            onClick={
              isActive
                ? e => {
                    e.preventDefault();
                    navigate('/');
                  }
                : undefined
            }
            {...rest}
          >
            {icon}
            {title}
          </Button>
        );
      })}
    </Flex>
  );
};
