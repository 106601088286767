import { formatCredit } from 'helpers/numeral';
import { WS_HANDLER_MESSAGE, WS_MESSAGE } from 'helpers/ws';
import {
  changeChatColor,
  changeUserBadge,
  userWebsocket,
} from 'services/user/modules/user';
import { createReducer } from 'store';
import { GameType, UserType, WagerItemType } from 'types';
import {
  acceptFriendInvite,
  addBlock,
  changeChannel,
  changeTimestampVisible,
  chatWebsocket,
  deleteMessages,
  friendInvite,
  loadChannelMessages,
  loadChatNotification,
  loadFriends,
  removeBlock,
  removeFriend,
} from './actions';
import { MAX_MESSAGES_COUNT } from './constants';
import { FriendType, MessageType, StateType } from './types';

const initialState: StateType = {
  users: {},
  groups: [],
  channels: {},
  channelMessages: {},
  newMessages: {},
  channel: -1,
  friends: [],
  blocked: [],
  friendsLoaded: false,
  isTimestampVisible: Boolean(localStorage.getItem('CHAT_TIMESTAMPS')),
  notification: null,
  notificationIsLoaded: false,
};

const defaultChannel = 1;

const acceptFriend = (
  state: StateType,
  { payload }: { payload: { user: UserType; channel: number } },
) => {
  if (payload) {
    const friend = state.friends.find(x => x.user.id === payload.user.id);

    if (friend) {
      friend.status = 'friends';
      friend.channel = payload.channel;
      state.channels[payload.channel] = {
        id: payload.user.id,
        name: payload.user.name,
        description: '',
        loaded: false,
        private: true,
        avatar: payload.user.icon,
        level: payload.user.level,
        visible: false,
        lastMessageTimestamp: 0,
        channel: payload.channel,
      };
    }
  }
};

export const reducer = createReducer(initialState, {
  [WS_MESSAGE(chatWebsocket, 'GROUPS')]: (state, { payload }) => {
    state.groups = payload;

    for (let i = 0; i < payload.length; i += 1) {
      const group = payload[i];

      if (group.channel !== 1) {
        continue;
      }

      if (!(group.channel in state.channelMessages)) {
        state.channelMessages[group.channel] = [];
      }

      state.channels[group.channel] = {
        id: group.id,
        name: group.name,
        description: group.description,
        loaded: false,
        private: false,
        visible: true,
        avatar: '',
        channel: group.channel,
        lastMessageTimestamp: 0,
      };

      if (defaultChannel === group.channel && state.channel === -1) {
        state.channel = defaultChannel;
      }
    }
  },

  [WS_HANDLER_MESSAGE(userWebsocket, 'ChatRain', 'chatrainannouncement')]: (
    state,
    { payload: { channelId, message, user } },
  ) => {
    state.channelMessages[channelId] = [
      ...state.channelMessages[channelId],
      {
        id: String(Date.now()),
        userId: user.id,
        timestamp: Math.floor(Date.now() / 1000).toString(),
        message,
        isChatRainAnnouncement: true,
      },
    ];
    state.users[user.id] = user;
  },

  [WS_MESSAGE(userWebsocket, 'WAGERFEED')]: (
    state,
    {
      payload,
      games,
      username,
    }: {
      payload: WagerItemType[];
      username: string;
      games: { [key: string]: GameType };
    },
  ) => {
    const channelId = state.channel;
    if (!state.channels[channelId]?.private) {
      payload.forEach(item => {
        const isBigWin = item.wagerAmount * 100 <= item.winAmount;

        if (isBigWin && item.user?.name && username !== item.user.name) {
          const { user } = item;
          const gameName = games[item.subGameType]?.title;

          // Create a link to /race using HTML <a> tag
          const gameNameLink = `<Link to='/'>${gameName}</Link>`;

          state.channelMessages[channelId] = [
            ...state.channelMessages[channelId],
            {
              id: String(Date.now()),
              userId: user.id,
              timestamp: Math.floor(Date.now() / 1000).toString(),
              message: `just won $${formatCredit(
                item.winAmount,
              )} with a ${formatCredit(
                item.winAmount / item.wagerAmount,
              )}x multiplier on ${gameNameLink}`,
              isChatRainAnnouncement: true,
            },
          ];
          state.users[user.id] = user;
        }
      });
    }
  },

  [WS_MESSAGE(chatWebsocket, 'CHAT')]: (
    state,
    {
      payload,
      username,
      userId,
    }: {
      payload: {
        users: { [key: string]: UserType };
        channel: number;
        messages: MessageType[];
      };
      username: string;
      userId: string;
    },
  ) => {
    const isBot = Object.values(payload.users).some(u => u.isBot);

    if (isBot && !state.channels[payload.channel]) {
      const { id, name, icon } = Object.values(payload.users)[0];
      state.channels[payload.channel] = {
        id,
        name,
        avatar: icon,
        isBot: true,
        level: 0,
        description: '',
        loaded: false,
        private: true,
        visible: true,
        lastMessageTimestamp: Date.now(),
        channel: payload.channel,
      };
      state.channelMessages[payload.channel] = [];
    }

    const currentMessages = state.channelMessages[payload.channel];

    if (payload.channel !== state.channel) {
      const channel = state.channels[payload.channel];
      if (
        (channel?.private ||
          payload?.messages.some(m => m.message.includes(`@[${username}]`))) &&
        payload?.messages.every(m => m.userId !== userId)
      ) {
        if (state.newMessages[payload.channel]) {
          state.newMessages[payload.channel] += 1;
        } else {
          state.newMessages[payload.channel] = 1;
        }
      }
    }

    if (
      state.channels[payload.channel] &&
      !state.channels[payload.channel].visible
    ) {
      state.channels[payload.channel].visible = true;
    }

    if (state.channels[payload.channel]) {
      state.channels[payload.channel].lastMessageTimestamp = Date.now();
    }

    if (!currentMessages) {
      return;
    }

    if (state.channels[payload.channel].loaded) {
      const totalMessageCount =
        currentMessages.length + payload.messages.length;

      if (totalMessageCount > MAX_MESSAGES_COUNT) {
        state.channelMessages[payload.channel] = [
          ...state.channelMessages[payload.channel].slice(
            payload.messages.length - MAX_MESSAGES_COUNT,
          ),
          ...payload.messages,
        ];
      } else {
        state.channelMessages[payload.channel] = [
          ...state.channelMessages[payload.channel],
          ...payload.messages,
        ];
      }
    }

    // if (isBot) {
    // state.channel = payload.channel;
    // delete state.newMessages[payload.channel];
    // }

    state.users = { ...state.users, ...payload.users };
  },

  [changeChannel.toString()]: (state, { payload }) => {
    state.channel = payload.channel;
    state.channels[payload.channel].visible = true;
    delete state.newMessages[payload.channel];
  },

  [loadChannelMessages.toString()]: (state, { payload }) => {
    state.channelMessages[payload.channel] = [
      ...payload.messages,
      { id: Date.now(), type: 'welcome' },
    ];
    state.users = { ...state.users, ...payload.users };

    state.channels = {
      ...state.channels,
      [payload.channel]: {
        ...state.channels[payload.channel],
        loaded: true,
      },
    };
  },

  [deleteMessages.toString()]: (
    state: StateType,
    { meta: { startChatMessageId, channelId } },
  ) => {
    if (state.channelMessages[channelId]) {
      state.channelMessages[channelId] = state.channelMessages[
        channelId
      ].filter(msg => msg.id !== startChatMessageId);
    }
  },

  [addBlock.toString()]: (
    state: StateType,
    { meta: { userName } }: { meta: { userName: string } },
  ) => {
    state.blocked.push(userName);
    if (state.channels[state.channel].name === userName) {
      state.channel = 1;
    }
  },

  [removeBlock.toString()]: (
    state,
    { meta: { userName } }: { meta: { userName: string } },
  ) => {
    state.blocked = state.blocked.filter(u => u !== userName);
  },

  [loadFriends.toString()]: (
    state: StateType,
    { payload }: { payload: FriendType[] },
  ) => {
    state.friendsLoaded = true;
    state.friends = payload
      .filter(x => x.status !== 'blocked' && x.status !== 'hidden')
      .map(f => ({
        ...f,
        lastUpdatedAt:
          f.lastUpdatedAt && Number(f.lastUpdatedAt) > 0
            ? f.lastUpdatedAt * 1000
            : 0,
      }));

    state.blocked = payload
      .filter(x => x.status === 'blocked')
      .map(f => f.user.name);

    payload.forEach(x => {
      if (x.channel && x.status === 'friends') {
        state.channels = {
          ...state.channels,
          [x.channel]: {
            id: x.user.id,
            name: x.user.name,
            description: '',
            loaded: false,
            private: true,
            isBot: x.user.isBot || false,
            avatar: x.user.icon,
            level: x.user.level,
            visible: x.hasMessages,
            channel: x.channel,
            lastMessageTimestamp: x.lastMessageTimestamp
              ? Number(x.lastMessageTimestamp * 1000)
              : 0,
          },
        };

        if (!state.channelMessages[x.channel]) {
          state.channelMessages[x.channel] = [];
        }
      }
    });
  },

  [friendInvite.toString()]: (state: StateType, { payload }) => {
    if (payload) {
      state.friends.unshift(payload);
    }
  },

  [acceptFriendInvite.toString()]: acceptFriend,

  [removeFriend.toString()]: (state: StateType, { meta: { userName } }) => {
    state.friends = state.friends.filter(x => x.user.name !== userName);
    if (state.channels[state.channel].name === userName) {
      state.channel = 1;
    }
  },

  [WS_MESSAGE(chatWebsocket, 'CHAT_SEND_FRIEND_INVITE')]: (
    state,
    { payload },
  ) => {
    state.friends.unshift({
      status: 'request',
      user: payload.user,
      lastUpdatedAt: Date.now(),
      online: false,
    });
  },

  [WS_MESSAGE(chatWebsocket, 'CHAT_ACCEPT_FRIEND_INVITE')]: acceptFriend,

  [WS_MESSAGE(chatWebsocket, 'CHAT_REMOVE_FRIEND')]: (state, { payload }) => {
    if (Array.isArray(state.friends)) {
      state.friends = state.friends.filter(x => x.user.id !== payload.user.id);
    }
  },

  [WS_MESSAGE(chatWebsocket, 'FRIEND-ONLINE')]: (state, { payload }) => {
    const friend = state.friends.find(x => x.user.id === payload.id);

    if (friend) {
      friend.online = payload.status;
      friend.lastUpdatedAt = Date.now();
    }
  },

  [WS_MESSAGE(chatWebsocket, 'FRIEND-ROUTE')]: (state, { payload }) => {
    const friend = state.friends.find(x => x.user.id === payload.id);

    if (friend) {
      friend.currentRoute = payload.route;
      friend.lastUpdatedAt = Date.now();
    }
  },

  [WS_MESSAGE(chatWebsocket, 'chat_delete')]: (
    state,
    { payload: { channelId, startChatMessageId } },
  ) => {
    if (state.channelMessages[channelId]) {
      state.channelMessages[channelId] = state.channelMessages[
        channelId
      ].filter(msg => msg.id !== startChatMessageId);
    }
  },
  [changeChatColor.toString()]: (
    state: StateType,
    { meta: { color, userId, loading } },
  ) => {
    if (loading && state.users[userId]) {
      state.users[userId].chatColor = color;
    }
  },
  [changeUserBadge.toString()]: (
    state: StateType,
    { meta: { badge, userId, imageUrl } },
  ) => {
    if (state.users[userId]) {
      state.users[userId].badges = [{ name: badge, imageUrl }];
    }
  },
  [changeTimestampVisible.toString()]: (
    state: StateType,
    { payload }: { payload: boolean },
  ) => {
    state.isTimestampVisible = payload;
  },
  [loadChatNotification.toString()]: (state, { payload }) => {
    state.notification = payload?.[0]?.text;
    state.notificationIsLoaded = true;
  },
});
