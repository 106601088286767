import { client } from 'helpers/client';

export const loadQuests = () =>
  client
    .get(`/core/user/quests/all?active=true`)
    .then(({ data: { payload } }) => payload);

export const loadInactiveQuests = ({ limit = 24, before = null } = {}) =>
  client
    .get(`/core/user/quests/all?active=false&limit=${limit}&before=${before}`)
    .then(({ data: { payload } }) => payload);
