import { PopularWithFriendsIcon } from 'icons';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GameSlider } from 'services/games/components/game-slider';
import {
  getSlotsFriendsPopularGames,
  getSlotsIsFriendsPopularLoaded,
  getSlotsIsLoaded,
  loadPopularWithFriends,
} from 'services/games/modules/slots';
import { getUserIsAuthorized } from 'services/user/modules/user';
import { useActions } from 'store';

const meta = { isPopularFriends: true };

export const PopularWithFriendsSlider = () => {
  const { t } = useTranslation();
  const games = useSelector(getSlotsFriendsPopularGames);
  const isLoaded = useSelector(getSlotsIsLoaded);
  const isAuthorized = useSelector(getUserIsAuthorized);
  const isPopularFriendsLoaded = useSelector(getSlotsIsFriendsPopularLoaded);

  const actions = useActions({
    loadPopularWithFriends,
  });

  useEffect(() => {
    if (!isPopularFriendsLoaded && isAuthorized) {
      actions.loadPopularWithFriends();
    }
  }, [isPopularFriendsLoaded, isAuthorized, actions]);

  return (
    <GameSlider
      icon={<PopularWithFriendsIcon color="candy-floss-text" />}
      slides={games}
      meta={meta}
      isLoaded={isLoaded}
      title={t('casino.slider.popular-with-friends.title')}
      titleLink="/casino/category/popular-with-friends"
    />
  );
};
