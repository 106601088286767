import { reducer as app } from 'modules/app';
import { reducer as chat } from 'modules/chat';
import { reducer as handler } from 'modules/handler';
import { reducer as wagers } from 'modules/wagers';
import { combineReducers } from 'redux';
import { reducer as blog } from 'services/cms/modules/blog';
import { reducer as pages } from 'services/cms/modules/pages';
import { reducer as promo } from 'services/cms/modules/promo';
import { reducer as jackpot } from 'services/games/modules/jackpot';
import { reducer as quests } from 'services/games/modules/quests';
import { reducer as roulette } from 'services/games/modules/roulette';
import { reducer as slots } from 'services/games/modules/slots';
import { reducer as tournaments } from 'services/games/modules/tournaments';
import { reducer as versus } from 'services/games/modules/versus';
import { reducer as nft } from 'services/nft/modules/nft';
import { reducer as affiliates } from 'services/user/modules/affiliates';
import { reducer as currencies } from 'services/user/modules/currencies';
import { reducer as social } from 'services/user/modules/social';
import { reducer as user } from 'services/user/modules/user';

export const reducers = combineReducers({
  wagers,
  nft,
  slots,
  blog,
  promo,
  pages,
  social,
  roulette,
  user,
  chat,
  handler,
  currencies,
  jackpot,
  versus,
  affiliates,
  quests,
  app,
  tournaments,
});
