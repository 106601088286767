import { EMAIL_CONFIRMATION_LINK } from 'constants/general';
import { getToken } from 'helpers/client';
import { getFunctioningUrl } from 'helpers/env';
import { wsconnect } from 'helpers/ws';
import { createAction } from 'store';
import * as api from './api';

export const userWebsocket = 'user/websocket';

export const websocketConnect = () =>
  wsconnect(
    userWebsocket,
    getFunctioningUrl(process.env.REACT_APP_API_WS_URL || '', 'wss:'),
  );

export const loadUser = createAction(
  'user/loadUser',
  () => () =>
    getToken()
      ? api.loadUser().catch(() => Promise.resolve())
      : Promise.resolve(),
);

export const loadSlimUser = createAction(
  'user/loadSlimUser',
  username => () => api.loadSlimUser(username),
  () => ({ _loading: true }),
);

export const loadUserStats = createAction(
  'user/loadUserStats',
  username => () => api.loadUserStats(username),
  username => ({ username }),
);

export const loadTransactionHistory = createAction(
  'user/loadTransactionHistory',
  ({ limit = 20, before = null, username = null, search, type }) =>
    () =>
      api.loadTransactionHistory({ limit, before, username, search, type }),
  ({ username }) => ({ username }),
);

export const cancelWithdrawal = createAction(
  'user/cancelWithdrawal',
  (id: string) => () => api.cancelWithdrawal(id),
);

export const saveSettings = createAction(
  'user/saveSettings',
  settings => () => api.saveSettings(settings),
  settings => ({ settings, _loading: true }),
);
export const changePassword = createAction(
  'user/changePassword',
  ({ oldPassword, newPassword }) =>
    () =>
      api.changePassword({ oldPassword, newPassword }),
);

export const ban = createAction(
  'user/ban',
  userName => () => api.ban(userName),
);

export const unban = createAction(
  'user/unban',
  userName => () => api.unban(userName),
);

export const handlerban = createAction(
  'user/handlerban',
  (userName, handlerIds) => () => api.handlerban(userName, handlerIds),
);

export const handlerunban = createAction(
  'user/handlerunban',
  (userName, handlerIds) => () => api.handlerunban(userName, handlerIds),
);

export const redeemRakeback = createAction(
  'user/redeemRakeback',
  userId => () => api.redeemRakeback(userId),
);

export const setExclusion = createAction(
  'user/setExclusion',
  (userId, exclude) => () => api.setExclusion(userId, exclude),
);

export const changeEmail = createAction(
  'user/changeEmail',
  email => async () => {
    await api.changeEmail({
      email,
      emailConfirmReturnUrl: `${window.location.origin}${EMAIL_CONFIRMATION_LINK}`,
    });
    return email;
  },
);

export const resendConfirmEmail = createAction(
  'user/resendConfirmEmail',
  () => () =>
    api.resendConfirmEmail({
      emailConfirmReturnUrl: `${window.location.origin}${EMAIL_CONFIRMATION_LINK}`,
    }),
);

export const activateBonus = createAction(
  'user/activateBonus',
  ({ bonusBalanceId, isDepositReload }) =>
    () =>
      api.activateBonus({ bonusBalanceId, isDepositReload }),
  params => ({ ...params, _loading: true }),
);

export const deactivateBonus = createAction(
  'user/deactivateBonus',
  ({ bonusBalanceId, isDepositReload }) =>
    () =>
      api.deactivateBonus({ bonusBalanceId, isDepositReload }),
  params => ({ ...params, _loading: true }),
);

export const startBonusSession = createAction(
  'user/startBonusSession',
  ({ bonusBalanceId }) =>
    () =>
      api.startBonusSession({ bonusBalanceId }),
  params => params,
);

export const stopBonusSession = createAction(
  'user/stopBonusSession',
  () => () => api.stopBonusSession(),
  params => params,
);

export const pauseBonusSession = createAction(
  'user/pauseBonusSession',
  () => () => api.pauseBonusSession(),
  params => params,
);

export const resumeBonusSession = createAction(
  'user/resumeBonusSession',
  () => () => api.resumeBonusSession(),
  params => params,
);

export const cancelBonusSession = createAction(
  'user/cancelBonusSession',
  ({ bonusBalanceId }) =>
    () =>
      api.cancelBonusSession({ bonusBalanceId }),
  params => params,
);

export const transferBonus = createAction(
  'user/transferBonus',
  params => () => api.transferBonus(params),
  params => params,
);

export const enable2fa = createAction(
  'auth/enable2fa',
  (code, password) => () => api.enable2fa({ code, password }),
  () => ({ _loading: true }),
);

// export const generate2faRecoveryCodes = createAction(
//   'auth/generate2faRecoveryCodes',
//   () => () => api.generate2faRecoveryCodes(),
// );

export const get2faInfo = createAction(
  'auth/get2faInfo',
  () => () => api.get2faInfo(),
  () => ({ _loading: true }),
);

export const reset2fa = createAction(
  'auth/reset2fa',
  (code, password) => () => api.reset2fa({ code, password }),
  () => ({ _loading: true }),
);

export const setUseLevelOverNft = createAction(
  'user/setUseLevelOverNft',
  value => () => api.setUseLevelOverNft(value),
  value => ({ _loading: true, value }),
);

export const shareBigWins = createAction(
  'user/shareBigWins',
  value => () => api.shareBigWins(value),
  value => ({ value }),
);

export const searchUsers = createAction(
  'user/searchUsers',
  name => () => api.searchUsers(name),
);

export const selfExclude = createAction(
  'user/selfExclude',
  hours => () => api.selfExclude(hours),
);

export const verifySocial = createAction(
  'user/verifySocial',
  (social, code) => () => api.verifySocial(social, code),
  social => ({ social }),
);

export const removeSocial = createAction(
  'user/removeSocial',
  social => () => api.removeSocial(social),
  social => ({ social }),
);

export const startFreeSpin = createAction(
  'user/startFreeSpin',
  freespinId => () => api.startFreeSpin({ freespinId }),
  id => ({ id }),
);

export const loadKYCAccessToken = createAction(
  'user/loadKYCAccessToken',
  () => () => api.loadKYCAccessToken(),
);

export const loadSessionsList = createAction(
  'user/loadSessionsList',
  () => () => api.loadSessionsList(),
);

export const cancelSession = createAction(
  'user/cancelSession',
  id => () => api.cancelSession(id),
  id => ({ id }),
);

export const changeChatColor = createAction(
  'user/changeChatColor',
  color => () => api.changeChatColor(color),
  (color, userId) => ({ color, userId, _loading: true }),
);

export const loadUserNfts = createAction(
  'user/loadUserNfts',
  username => () => api.loadUserNfts(username),
  username => ({ username }),
);

export const changeUserBadge = createAction(
  'user/changeUserBadge',
  (badge, userId, id) => () => api.changeUserBadge(id),
  (badge, userId, id, imageUrl) => ({ badge, userId, imageUrl }),
);

export const removeUserBadges = createAction('user/removeUserBadges', () =>
  api.removeUserBadges(),
);

export const loadUserBadges = createAction(
  'user/loadUserBadges',
  () => () => api.loadUserBadges(),
);

export const loadUserBadge = createAction(
  'user/loadUserBadge',
  () => () => api.loadUserBadge(),
);

export const loadAffiliateCampaignDetails = createAction(
  'user/loadAffiliateCampaignDetails',
  stag => () => api.loadAffiliateCampaignDetails(stag),
);

export const changeDepositStatus = createAction('user/changeDepositStatus');
