import { Box } from '@chakra-ui/react';
import { Content } from 'components/content';
import {
  MyBetsIcon,
  RafflesIcon,
  StatisticsIcon,
  TransactionsIcon,
} from 'icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RaceTable } from 'services/games/components/race-table';
import { useTournamentPrize } from 'services/games/hooks/use-tournament-prize';
import {
  getTournamentsIsLoaded,
  getTournamentsList,
  loadTournaments,
} from 'services/games/modules/tournaments';
import { TournamentType } from 'services/games/modules/tournaments/types';
import {
  getUserId,
  getUserIsAuthorized,
  getUserName,
} from 'services/user/modules/user';
import { useActions } from 'store';
import { Tabs } from 'uikit/tabs';
import { TournamentBanner } from '../tournament-banner';
import { List } from './components/list';

const types = ['individual', 'all', 'high-rollers'];

export const Wagers = () => {
  const [tab, setTab] = useState(1);
  const userId = useSelector(getUserId);
  const isAuthorized = useSelector(getUserIsAuthorized);
  const type = types[tab];
  const { t } = useTranslation();

  const handleTabChange = (index: number) => {
    setTab(index);
  };

  const tournaments = useSelector(getTournamentsList);
  const isLoaded = useSelector(getTournamentsIsLoaded);

  const tournament = tournaments.find(tour => tour?.feType === 'WEEKLY');
  const { roundEndDate, currentPrizePool, maxPossiblePrizePool } =
    tournament || ({} as TournamentType);

  const actualPrize = useTournamentPrize(true, {
    currentPrizePool,
    maxPossiblePrizePool,
    roundEndDate,
  });

  const username = useSelector(getUserName);

  const {
    entries: tournamentEntries = [],
    // @ts-ignore
    maxWinnersPerRound,
    // @ts-ignore
    rank,
    // @ts-ignore
    score,
  } = tournament || {};

  let entries = tournamentEntries;

  if (rank && rank > maxWinnersPerRound) {
    entries = entries.concat([
      // @ts-ignore
      { prize: '0', rank, score, user: { name: username } },
    ]);
  }

  const actions = useActions({ loadTournaments });

  useEffect(() => {
    if (!isLoaded) {
      actions.loadTournaments();
    }
  }, [actions, isLoaded]);

  return (
    <Box mt="-3">
      <Box>
        <Tabs
          index={tab}
          onChange={handleTabChange}
          tabs={[
            {
              Icon: MyBetsIcon,
              children: t('wagers.my-bets'),
              style: {
                opacity: isAuthorized ? 1 : 0.5,
                pointerEvents: isAuthorized ? 'auto' : 'none',
              },
            },
            {
              Icon: TransactionsIcon,
              children: t('transactions.bets'),
            },
            {
              Icon: StatisticsIcon,
              children: t('wagers.big-wins'),
            },

            {
              Icon: RafflesIcon,
              children: t('tournaments.weekly-race'),
            },
          ]}
        />
      </Box>
      <Box p="5" w="full" maxW="6xl" mx="auto">
        <Content key={type}>
          {tab === 3 ? (
            <Box>
              <Box mb="5">
                <TournamentBanner />
              </Box>
              <RaceTable
                entries={entries}
                isMultiplier={false}
                currentPrizePool={actualPrize}
                maxWinnersPerRound={maxWinnersPerRound}
              />
            </Box>
          ) : (
            <List
              isInfiniteLoop={false}
              key={type}
              type={type}
              userId={userId}
            />
          )}
        </Content>
      </Box>
    </Box>
  );
};
