/* eslint-disable max-lines */
import { Box, Button, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { Content } from 'components/content';
import { Loading } from 'components/loading';
import { PreviewGrid } from 'components/preview-grid';
import dayjs from 'dayjs';
import { removeIntercomToken, removeToken } from 'helpers/client';
import { formatCredit } from 'helpers/numeral';
import {
  AddFriendIcon,
  AffiliateIcon,
  BlockIcon,
  ChatIcon,
  CheckIcon,
  FriendRequestedIcon,
  LogoutIcon,
  ProfileIcon,
  SettingsIcon,
  TipIcon,
} from 'icons';
import { UnverifiedNotification } from 'layout/layout/components/unverified-notification';
import { MODAL_TYPE, useModal } from 'modals/index';
import {
  acceptFriendInvite,
  addBlock,
  changeChannel,
  friendInvite,
  getChatBlocked,
  getFriendsList,
  removeBlock,
  removeFriend,
} from 'modules/chat';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  HANDLERS_ORIGIN_GAMES,
  getSlotsGamesData,
} from 'services/games/modules/slots';
import { NftPreview } from 'services/nft/components/nft-preview';
import {
  LEVELS,
  getUserIsAuthorized,
  getUserLevel,
  getUserName,
  getUserNfts,
  getViewUser,
  getViewUserIsLoading,
  getViewUserStats,
  loadSlimUser,
  loadUserNfts,
  loadUserStats,
} from 'services/user/modules/user';
import { useActions } from 'store';
import { toastInfo, toastSuccess } from 'toasts';
import { MoreDropdown } from 'uikit/more-dropdown';
import { Skeleton } from 'uikit/skeleton';
import { StatsGrid } from 'uikit/stats-grid';
import { Tabs } from 'uikit/tabs';
import { TabType } from 'uikit/tabs/components/tab';
import { Affiliates } from './components/affiliates';
import { Settings } from './components/settings';

type Props = {
  onClose: () => void;
  contentProps: {
    username: string;
    tab?: string;
  };
};

export const Profile: React.FC<Props> = ({
  contentProps: { username, tab = '' },
  onClose,
}) => {
  const { onOpen: openRemoveFriendModal } = useModal(
    MODAL_TYPE.REMOVE_FRIEND_MODAL,
    { history: false },
  );
  const { onOpen: openTipModal } = useModal(MODAL_TYPE.TIP);
  // const [tabIndex, setTabIndex] = useState(0);
  const level = useSelector(getUserLevel);
  const levelName = LEVELS[level]?.name || '';

  const myUserName = useSelector(getUserName);
  const user = useSelector(getViewUser);
  const isLoading = useSelector(getViewUserIsLoading);
  const friends = useSelector(getFriendsList);
  const blocked = useSelector(getChatBlocked);
  const nfts = useSelector(getUserNfts(username));
  const stats = useSelector(getViewUserStats(username));
  const games = useSelector(getSlotsGamesData);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const isAuthorized = useSelector(getUserIsAuthorized);

  const actions = useActions({
    loadSlimUser,
    acceptFriendInvite,
    friendInvite,
    removeFriend,
    addBlock,
    removeBlock,
    changeChannel,
    loadUserNfts,
    loadUserStats,
  });

  const [isActionLoading, setIsActionLoading] = useState(false);

  const loadAction = async (pendingAction: Promise<unknown>) => {
    setIsActionLoading(true);
    try {
      await pendingAction;
    } finally {
      setIsActionLoading(false);
    }
  };

  const handleUnblock = async () => {
    await actions.removeBlock(username);
    toastSuccess({
      title: t('profile.unblocked-title'),
      description: t('profile.unblocked-description', { username }),
    });
  };

  const handleBlock = async () => {
    await actions.addBlock(username);
    toastInfo({
      title: t('profile.blocked-title'),
      description: t('profile.blocked-description', { username }),
    });
  };

  const isMe = username === myUserName;

  useEffect(() => {
    if (username) {
      actions.loadSlimUser(username);
      actions.loadUserStats(username);
      // setTabIndex(0);
    }
  }, [actions, username]);

  useEffect(() => {
    actions.loadUserNfts(username);
  }, [actions, username]);

  const handleLogout = useCallback(() => {
    window?.Intercom?.('shutdown');
    removeToken();
    removeIntercomToken();
    window.location.href = '/';
  }, []);

  if (!user) {
    return <Loading pos="relative" h="70vh" />;
  }

  const friend = friends.find(f => f.user.name === username);

  const statsGames = stats?.topGames
    ?.map(game => {
      const name =
        game.gameSubType ||
        HANDLERS_ORIGIN_GAMES[
          game.handlerId as keyof typeof HANDLERS_ORIGIN_GAMES
        ];
      return games[name];
    })
    .filter(g => g);

  const statsItems = stats
    ? [
        {
          title: t('transactions.bets'),
          content: formatCredit(stats?.debitCount),
        },
        {
          title: t('profile.wins'),
          content: formatCredit(stats?.creditCount),
        },
        {
          title: t('affiliates.wagered'),
          content: `$${formatCredit(stats?.debitAmount)}`,
        },
      ]
    : [];

  const getFriendshipButton = () => {
    if (isMe || isLoading) return null;
    switch (friend?.status) {
      case 'friends':
        return (
          <Button
            size="md"
            rounded="full"
            onClick={() => openRemoveFriendModal({ username })}
            leftIcon={<CheckIcon />}
            isLoading={isActionLoading}
          >
            {t('friends.friends')}
          </Button>
        );
      case 'pending':
        return (
          <Button
            size="md"
            rounded="full"
            onClick={() => loadAction(actions.removeFriend(username))}
            leftIcon={<FriendRequestedIcon />}
            isLoading={isActionLoading}
          >
            {t('friends.requested')}
          </Button>
        );
      case 'request':
        return (
          <Button
            size="md"
            rounded="full"
            onClick={() =>
              loadAction(
                actions.acceptFriendInvite(username).then(() =>
                  toastSuccess({
                    title: t('friends.request-accepted'),
                    description: t('friends.request-accepted-description', {
                      username,
                    }),
                    icon: user?.icon,
                    name: username,
                  }),
                ),
              )
            }
            isLoading={isActionLoading}
          >
            {t('friends.accept')}
          </Button>
        );
      default:
        return (
          <Button
            size="md"
            colorScheme="purple"
            leftIcon={<AddFriendIcon />}
            onClick={() =>
              loadAction(
                actions.friendInvite(username).then(() => {
                  toastSuccess({
                    title: t('friends.request-sent'),
                    description: t('friends.request-sent-description', {
                      username,
                    }),
                    icon: user?.icon,
                    name: username,
                  });
                }),
              )
            }
            isLoading={isActionLoading}
          >
            {t('friends.add-friend')}
          </Button>
        );
    }
  };

  const buttonSection = (
    <Skeleton
      isLoaded={isMe || !isLoading || !!user}
      display="flex"
      gap="2"
      alignItems="center"
    >
      {getFriendshipButton()}
      {!isLoading && !isMe && (
        <MoreDropdown
          buttonSize="md"
          placement="bottom-start"
          options={[
            {
              title: t('tip.send-tip'),
              icon: TipIcon,
              onClick: () => {
                openTipModal({ username });
              },
            },
            ...(friend?.status === 'friends'
              ? [
                  {
                    title: t('chat.message'),
                    icon: ChatIcon,
                    onClick: () => {
                      actions.changeChannel(friend.channel);
                    },
                  },
                ]
              : []),
            blocked.includes(user?.name)
              ? {
                  title: t('chat.unblock'),
                  icon: BlockIcon,
                  onClick: handleUnblock,
                }
              : {
                  title: t('chat.block'),
                  icon: BlockIcon,
                  onClick: handleBlock,
                },
          ]}
        />
      )}
    </Skeleton>
  );

  let tabs: TabType[] = [
    { children: t('profile.overview'), Icon: ProfileIcon, name: '' },
  ];

  // if (nfts?.length && username !== 'ryhs') {
  //   tabs.push({
  //     children: t('profile.collectibles'),
  //     Icon: NFTIcon,
  //     name: 'nft',
  //   });
  // }

  if (isMe) {
    tabs = tabs.concat([
      {
        children: t('profile.settings.title'),
        Icon: SettingsIcon,
        name: 'settings',
      },
      {
        children: t('profile.affiliates'),
        Icon: AffiliateIcon,
        name: 'affiliates',
      },
      {
        children: t('authorized-section.logout'),
        onClick: handleLogout,
        Icon: LogoutIcon,
      },
    ]);
  }

  const tabIndex = tabs.findIndex(item => item.name === tab) || 0;

  return (
    <>
      <Box pos="sticky" top="var(--header-height)" bg="toffee-base" zIndex="20">
        <Tabs
          index={tabIndex}
          onChange={newIndex => {
            const newTab = tabs[newIndex].name;
            const { tab: _, ...params } = Object.fromEntries(searchParams);

            if (newTab) {
              params.tab = newTab;
            }

            setSearchParams(params);
          }}
          tabs={tabs}
          onClose={onClose}
          hasClose
        />
      </Box>

      <Content key={tabIndex} pb="5">
        <Box>
          {(tab === '' || tab === 'nft') && (
            <Flex maxW="6xl" px="5" mx="auto" py="5">
              <Flex
                justifyContent="space-between"
                align="center"
                p="5"
                bg="sugar-dust"
                rounded="xl"
                w="full"
                mx="auto"
                gap="3"
              >
                {isLoading ? (
                  <Skeleton
                    h={{ base: '48px', md: '57px' }}
                    w={{ base: '48px', md: '57px' }}
                    rounded="full"
                    flexShrink="0"
                  />
                ) : (
                  user.icon && (
                    <Image
                      src={user.icon}
                      h={{ base: '48px', md: '57px' }}
                      w={{ base: '48px', md: '57px' }}
                      rounded="full"
                      fallback={
                        <Skeleton
                          h={{ base: '48px', md: '57px' }}
                          w={{ base: '48px', md: '57px' }}
                          rounded="full"
                          flexShrink="0"
                        />
                      }
                    />
                  )
                )}

                <Flex justifyContent="space-between" align="end" w="full">
                  <Box>
                    <Text
                      display="inline"
                      verticalAlign="top"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      fontSize={{ base: 'xl', md: '2xl' }}
                      color="vanilla-text"
                      fontWeight="500"
                    >
                      {user.name}
                    </Text>
                    <Text color="candy-floss-text">
                      {t('tournaments.joined')}{' '}
                      {dayjs(
                        Number(user.accountCreationTimestamp) * 1000,
                      ).fromNow()}
                    </Text>
                  </Box>
                  {!isMe && <Flex mt="3">{buttonSection}</Flex>}
                </Flex>
              </Flex>
            </Flex>
          )}

          {tab === '' && (
            <Flex
              flexDirection="column"
              gap="5"
              w="full"
              maxW="6xl"
              px="5"
              mx="auto"
            >
              {isMe && <UnverifiedNotification />}
              {isMe && (
                <Box w="full">
                  <StatsGrid options={statsItems} />
                </Box>
              )}

              <>
                {!nfts && <Loading pos="relative" mt="5" />}
                {!!nfts?.length && (
                  <Box>
                    <Flex
                      justify="space-between"
                      align="center"
                      mb="5"
                      gap="3"
                      w="full"
                    >
                      <Box>
                        <Flex align="center" gap="2">
                          <Heading
                            fontWeight="500"
                            fontSize={{ base: 'lg', md: 'xl' }}
                          >
                            Sherbet NFTs
                          </Heading>
                        </Flex>
                      </Box>
                    </Flex>
                    <PreviewGrid Item={NftPreview} list={nfts} size="md" />
                  </Box>
                )}
              </>
            </Flex>
          )}
          {tab === 'affiliates' && <Affiliates />}
          {tab === 'settings' && <Settings />}
        </Box>
      </Content>
    </>
  );
};
