import { Button, Flex } from '@chakra-ui/react';
import { VERSUS_HANDLER_ID } from 'constants/general';
import { CustomInput } from 'forms/custom-input';
import { CustomSelect } from 'forms/custom-select';
import { useToastError } from 'hooks/use-toast-error';
import { MODAL_TYPE, useModal } from 'modals';
import { getHandlerSettings } from 'modules/handler';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createGame, getVersusGames } from 'services/games/modules/versus';
import { MIN_VERSUS_PLAYERS } from 'services/games/pages/versus/constants';
import {
  getUserBalance,
  getUserId,
  getUserIsAuthorized,
} from 'services/user/modules/user';
import { useActions } from 'store';
import { BetInput } from 'uikit/bet-input';
import { ConfirmButton } from 'uikit/confirm-button';
import { ColorSelect } from './components/color-select';

export const GameCreateForm = () => {
  const [playerCount, setPlayerCount] = useState(MIN_VERSUS_PLAYERS);
  const [colorIdx, setColorIdx] = useState(0);
  const { t } = useTranslation();
  const { onOpen: loginModalOpen } = useModal(MODAL_TYPE.AUTH);
  const actions = useActions({ createGame });
  const toastError = useToastError();

  const isAuthorized = useSelector(getUserIsAuthorized);
  const userId = useSelector(getUserId);
  const userBalance = useSelector(getUserBalance);

  const [betAmount, setBetAmount] = useState(1);
  const [loading, setLoading] = useState(false);

  const games = Object.values(useSelector(getVersusGames) ?? {}).filter(
    g => g.wagers[0]?.userId === userId && !g.details.winner,
  );

  const {
    minWager,
    maxWager: settingsMaxWager,
    maxCreatedGames,
  } = useSelector(getHandlerSettings(VERSUS_HANDLER_ID));

  const maxWager = Math.min(settingsMaxWager, userBalance);

  useEffect(() => {
    setBetAmount(value => Math.max(minWager, Math.min(maxWager, value)));
  }, [minWager, maxWager, betAmount]);

  const handlePlaceBet = () => {
    setLoading(true);
    actions
      .createGame({
        handlerId: VERSUS_HANDLER_ID,
        amount: betAmount,
        total: betAmount,
        players: Number(playerCount),
        position: Math.min(colorIdx, playerCount - 1),
      })
      .catch(toastError)
      .finally(() => setLoading(false));
  };

  const isPlaceBetUnavailable =
    betAmount > settingsMaxWager || games.length >= maxCreatedGames;

  return (
    <Flex gap="3" w="full" align="start" flexDirection="column">
      <BetInput
        as={CustomInput}
        type="number"
        value={betAmount}
        onChange={setBetAmount}
      />

      <CustomSelect
        label={t('versus.players')}
        name="players"
        value={playerCount}
        onChange={e => setPlayerCount(+e.target.value)}
        options={Array.from(Array(3).keys()).map(i => ({
          value: i + 2,
          title: i + 2,
        }))}
      />

      <ColorSelect
        colorIdx={colorIdx}
        setColorIdx={setColorIdx}
        playerCount={playerCount}
      />

      <Flex mt="2" w="full">
        {isAuthorized ? (
          <ConfirmButton
            colorScheme="purple"
            w="full"
            flexShrink="0"
            isDisabled={!isAuthorized || isPlaceBetUnavailable}
            isLoading={loading}
            onClick={handlePlaceBet}
          >
            {t('versus.create-game')}
          </ConfirmButton>
        ) : (
          <Button
            w="full"
            flexShrink="0"
            colorScheme="purple"
            onClick={() => loginModalOpen()}
          >
            {t('versus.create-game')}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};
