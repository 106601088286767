import { createIcon } from '@chakra-ui/icons';

export const DropsAndWinsIcon = createIcon({
  displayName: 'DropsAndWinsIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      d="M13.405 4.473a.557.557 0 0 1 .438.397l.367 1.302 1.23.465a.556.556 0 0 1 .111.984l-1.049.7-.003 1.323a.556.556 0 0 1-.914.426l-1.016-.848-1.21.354a.557.557 0 0 1-.682-.718l.435-1.241-.762-1.118a.56.56 0 0 1 .499-.87l1.262.087.746-1.023a.555.555 0 0 1 .548-.22ZM8.6.534l.513 7.84c.037.573-.43 1.067-1.045 1.102-.653.037-1.217-.46-1.18-1.102l.513-7.84c.02-.309.305-.545.636-.526.306.018.545.247.563.526ZM3.143 4.693l.746 1.023 1.262-.087a.55.55 0 0 1 .522.278.557.557 0 0 1-.023.591l-.762 1.118.435 1.241a.556.556 0 0 1-.683.719L3.43 9.22l-1.015.848a.556.556 0 0 1-.914-.426L1.498 8.32l-1.05-.7a.557.557 0 0 1 .113-.984l1.23-.465.366-1.302a.556.556 0 0 1 .986-.176Zm.769-1.122c.468-.946.933-1.806 1.322-3.16a.558.558 0 0 1 1.09.23l-.002.012c-.198 1.467-.575 2.513-.906 3.526l-.002.006a.813.813 0 1 1-1.502-.614Zm6.672.608c-.31-.948-.705-2.033-.906-3.526L9.676.64a.558.558 0 0 1 1.09-.229c.38 1.326.838 2.181 1.322 3.16a.813.813 0 1 1-1.5.618l-.004-.01ZM6.89 9.765l1.107.612 1.108-.612a.55.55 0 0 1 .59.032.557.557 0 0 1 .229.545l-.218 1.335.918.941a.556.556 0 0 1-.314.94l-1.246.19-.562 1.197a.555.555 0 0 1-1.009 0l-.562-1.197-1.246-.19a.557.557 0 0 1-.315-.94l.918-.941-.218-1.335a.556.556 0 0 1 .82-.577Z"
    />
  ),
});
