import { Box, Flex } from '@chakra-ui/react';
import { easeInOutCubic } from 'helpers/ease';
import { useEffect, useMemo, useRef, useState } from 'react';
import { VERSUS_COLORS } from 'services/games/pages/versus/constants';

export const HorizontalSpinner = ({
  spinTime,
  winner,
  maxPlayers,
  users = [],
}) => {
  // const [winner, setWinner] = useState(propWinner);
  const [isFinished, setIsFinished] = useState(winner);
  const spinnerRef = useRef(null);

  const pieces = useMemo(() => {
    const list = new Array(maxPlayers)
      .fill({})
      .map((_, index) => ({ amount: 1, id: index }));

    for (const { userId, position } of users) {
      list[position].id = userId;
    }

    return list;
  }, [maxPlayers, users]);

  useEffect(() => {
    if (!winner || users.length < 2) return undefined;

    const winnerIndex = pieces.findIndex(p => p.id === winner);

    const totalWidth = spinnerRef.current.offsetWidth;

    const itemWidth = totalWidth / pieces.length;
    const centerPixel = totalWidth / 2;

    const winnerOffset =
      centerPixel - (winnerIndex * itemWidth + itemWidth / 2) - totalWidth;

    const spinOffset = -totalWidth * 35 + winnerOffset;

    if (isFinished && winner) {
      spinnerRef.current.style.transform = `translateX(${winnerOffset % totalWidth}px)`;
      return undefined;
    }

    const start = performance.now();

    let animationFrame;
    const frameStep = timestamp => {
      const progress = easeInOutCubic((timestamp - start) / spinTime, 0, 1, 1);
      spinnerRef.current.style.transform = `translateX(${(progress * spinOffset) % totalWidth}px)`;
      if (progress < 1) {
        animationFrame = requestAnimationFrame(frameStep);
      } else {
        setIsFinished(true);
        spinnerRef.current.style.transform = `translateX(${winnerOffset % totalWidth}px)`;
      }
    };

    animationFrame = requestAnimationFrame(frameStep);
    return () => {
      cancelAnimationFrame(animationFrame);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [winner]);

  return (
    <>
      <Box pos="relative" overflow="hidden">
        <Flex w="full" h="2" ref={spinnerRef}>
          {pieces.map((piece, index) => (
            <Box
              w={`${100 / pieces.length}%`}
              flexShrink="0"
              key={`0_${index}`}
              bg={VERSUS_COLORS[index]}
            />
          ))}
          {pieces.map((piece, index) => (
            <Box
              w={`${100 / pieces.length}%`}
              flexShrink="0"
              key={`1_${index}`}
              bg={VERSUS_COLORS[index]}
            />
          ))}
        </Flex>
        <Box
          pos="absolute"
          left="calc(50% - 1px)"
          w="2px"
          h="2"
          top="0"
          bg="white"
        />
      </Box>
      {/* <Flex>
        {pieces.map((_, index) => (
          <button type="button" onClick={() => setWinner(pieces[index].id)}>
            Winner {index}
          </button>
        ))}
      </Flex> */}
    </>
  );
};
