import { client, parseApiError } from 'helpers/client';

export const loadUser = () =>
  client.get('/core/user').then(({ data }) => data.payload);

export const loadSlimUser = (username: string) =>
  client.get(`/core/user/${username}/slim`).then(({ data }) => data.payload);

export const loadUserStats = (username: string) =>
  client.get(`/core/user/${username}/totals`).then(({ data }) => data.payload);

export const loadTransactionHistory = ({
  username,
  limit,
  before = null,
  search = '',
  type,
}: {
  username: string;
  limit: number;
  before: string | null;
  search: string;
  type: string;
}) =>
  client
    .get(
      `/core/user${
        username ? `/${username}` : ''
      }/transaction/history?limit=${limit}&before=${before}&search=${search}&type=${type}`,
    )
    .then(({ data }) => data.payload);

export const cancelWithdrawal = (id: string) =>
  client
    .post('/handler/ethereum/11/cancel-withdraw', { withdrawId: id })
    .then(({ data }) => data.payload)
    .catch(parseApiError);

export const saveSettings = (settings: object) =>
  client
    .post('/core/user/settings', { settings })
    .then(({ data }) => data.payload)
    .catch(parseApiError);

export const ban = (userName: string) =>
  client.post('/core/admin/ban', { userName }).catch(parseApiError);

export const unban = (userName: string) =>
  client.post('/core/admin/unban', { userName }).catch(parseApiError);

export const handlerban = (userName: string, handlerIds: number[]) =>
  client
    .post('/core/admin/ban/handlers', { userName, handlerIds })
    .catch(parseApiError);

export const handlerunban = (userName: string, handlerIds: number[]) =>
  client
    .post('/core/admin/unban/handlers', { userName, handlerIds })
    .catch(parseApiError);

export const redeemRakeback = (userId: string) =>
  client.post('/core/user/rakeback', { userId }).catch(parseApiError);

export const setExclusion = (userName: string, exclude: unknown) =>
  client
    .post('/core/admin/exclude', { userName, exclude })
    .catch(parseApiError);

export const changePassword = ({
  oldPassword,
  newPassword,
}: {
  oldPassword: string;
  newPassword: string;
}) =>
  client
    .post(`/auth/change-password`, {
      oldPassword,
      newPassword,
    })
    .then(({ data }) => data)
    .catch(parseApiError);

export const changeEmail = ({
  email,
  emailConfirmReturnUrl,
}: {
  email: string;
  emailConfirmReturnUrl: string;
}) =>
  client
    .post(`/auth/change-email`, { email, emailConfirmReturnUrl })
    .then(({ data }) => data)
    .catch(parseApiError);

export const resendConfirmEmail = ({
  emailConfirmReturnUrl,
}: {
  emailConfirmReturnUrl: string;
}) =>
  client
    .post(`/auth/resend-confirm-email`, { emailConfirmReturnUrl })
    .then(({ data }) => data)
    .catch(parseApiError);

export const enable2fa = ({
  code,
  password,
}: {
  code: string;
  password: string;
}) =>
  client
    .post(`/auth/enable-2fa`, { code, password })
    .then(({ data }) => data)
    .catch(parseApiError);

// export const generate2faRecoveryCodes = () =>
//   client
//     .post(`/auth/generate-2fa-recovery-codes`)
//     .then(({ data }) => data)
//     .catch(parseApiError);

export const get2faInfo = () =>
  client
    .get(`/auth/info-2fa`)
    .then(({ data }) => data)
    .catch(parseApiError);

export const reset2fa = ({
  code,
  password,
}: {
  code: string;
  password: string;
}) =>
  client
    .post('/auth/reset-2fa', { code, password })
    .then(({ data }) => data)
    .catch(parseApiError);

export const activateBonus = (params: object) =>
  client
    .post(`/core/user/bonus/activate`, params)
    .then(({ data }) => data.payload)
    .catch(parseApiError);

export const deactivateBonus = (params: object) =>
  client
    .post(`/core/user/bonus/deactivate`, params)
    .then(({ data }) => data.payload)
    .catch(parseApiError);

export const startBonusSession = (params: object) =>
  client
    .post(`/core/user/bonus/start`, params)
    .then(({ data }) => data.payload)
    .catch(parseApiError);

export const stopBonusSession = () =>
  client
    .post('/core/user/bonus/stop')
    .then(({ data }) => data)
    .catch(parseApiError);

export const pauseBonusSession = () =>
  client
    .post('/core/user/bonus/pause')
    .then(({ data }) => data)
    .catch(parseApiError);

export const resumeBonusSession = () =>
  client
    .post('/core/user/bonus/resume')
    .then(({ data }) => data)
    .catch(parseApiError);

export const cancelBonusSession = ({
  bonusBalanceId,
}: {
  bonusBalanceId: string;
}) =>
  client
    .post('/core/user/bonus/cancel', { bonusBalanceId })
    .then(({ data }) => data)
    .catch(parseApiError);

export const transferBonus = (params: object) =>
  client
    .post(`/core/user/bonus/transfer`, params)
    .then(({ data }) => data)
    .catch(parseApiError);

export const setUseLevelOverNft = (value: string) =>
  client
    .post(`/core/user/uselevelovernft/${value}`)
    .then(({ data }) => data)
    .catch(parseApiError);

export const shareBigWins = (value: number) =>
  client
    .post('/core/user/sharebigwins', { winMultiplier: value, enabled: true })
    .then(({ data }) => data)
    .catch(parseApiError);

export const searchUsers = (name: string) =>
  client
    .get(`/core/user/search/${name}`)
    .then(({ data }) => data.payload)
    .catch(parseApiError);

export const selfExclude = (hours: number) =>
  client
    .post(`/core/user/self-exclude`, { hours })
    .then(({ data }) => data.payload)
    .catch(parseApiError);

export const verifySocial = (social: string, code: string) =>
  client
    .get(`/core/user/${social}/verify/${code}`)
    .then(({ data }) => data.payload)
    .catch(parseApiError);

export const removeSocial = (social: string) =>
  client
    .post(`/core/user/${social}/remove`)
    .then(({ data }) => data.payload)
    .catch(parseApiError);

export const startFreeSpin = (params: object) =>
  client
    .post('/handler/slots/20/freespins-start', params)
    .then(({ data }) => data.payload)
    .catch(parseApiError);

export const loadKYCAccessToken = () =>
  client
    .get('/core/user/kyc/access-token')
    .then(({ data }) => data.payload)
    .catch(parseApiError);

export const loadSessionsList = () =>
  client
    .get('/core/user/sessions/list')
    .then(({ data }) => data.payload)
    .catch(parseApiError);

export const cancelSession = (id: string) =>
  client
    .post(`/core/user/sessions/cancel/${id}`)
    .then(({ data }) => data.payload)
    .catch(parseApiError);

export const changeChatColor = (color: string) =>
  client
    .post(`/core/user/chat-color/${color}`)
    .then(({ data }) => data.payload)
    .catch(parseApiError);

export const loadUserNfts = (username: string) =>
  client
    .get(`/core/user/${username}/nfts`)
    .then(({ data }) => data.payload)
    .catch(parseApiError);

export const loadUserBadges = () =>
  client
    .get(`/core/user/earned-badges`)
    .then(({ data }) => data.payload)
    .catch(parseApiError);

export const loadUserBadge = () =>
  client
    .get(`/core/user/badges`)
    .then(({ data }) => data.payload)
    .catch(parseApiError);

export const changeUserBadge = (id: string) =>
  client
    .post(`/core/user/badges/${id}`)
    .then(({ data }) => data.payload)
    .catch(parseApiError);

export const removeUserBadges = () =>
  client
    .post(`/core/user/deselect-user-badges`, {})
    .then(({ data }) => data.payload)
    .catch(parseApiError);

export const loadAffiliateCampaignDetails = (stag: string) =>
  client
    .get(`/core/user/affiliate-campaign-detail?stag=${stag}`)
    .then(({ data }) => data.payload);
