import { BigInteger } from 'big-integer/BigInteger';
import { isCommandAvailable, parseUserName } from 'helpers/command';
import { MuteIcon, RainIcon, RecentIcon, TipIcon } from 'icons';
import { MODAL_TYPE, useModal } from 'modals';
import {
  mute,
  removeMute,
  removeTimeout,
  sendMessage,
  timeout,
} from 'modules/chat';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createCoupon } from 'services/user/modules/coupon';
import {
  PermissionFlags,
  ban,
  getUserPermissionFlags,
  handlerban,
  handlerunban,
  setExclusion,
  unban,
} from 'services/user/modules/user';
import { useActions } from 'store';
import { toastSuccess } from 'toasts';

export const useChatCommands = ({ onRainOpen }: { onRainOpen: () => void }) => {
  const actions = useActions({
    sendMessage,
    timeout,
    ban,
    mute,
    removeMute,
    removeTimeout,
    unban,
    createCoupon,
    handlerban,
    handlerunban,
    setExclusion,
  });

  const { onOpen: openTipModal } = useModal(MODAL_TYPE.TIP);

  const userPermissions = useSelector(getUserPermissionFlags);

  return useMemo(
    () =>
      [
        {
          icon: TipIcon,
          command: 'tip',
          args: ['amount', 'user'],
          description: <span>/tip @user [amount]</span>,
          flag: null,
          unlimitedArgs: true,
          handler: ([user, tip]: string[]) => {
            if (!user) {
              return openTipModal();
            }

            const username = parseUserName(user);

            if (!tip) {
              return openTipModal({
                username,
              });
            }

            return openTipModal({
              username,
              tip,
            });
          },
        },
        {
          icon: RainIcon,
          command: 'rain',
          args: [],
          description: <span>/rain</span>,
          handler: onRainOpen,
        },

        {
          icon: MuteIcon,
          command: 'mute',
          args: ['user'],
          description: <span>/mute @user</span>,
          flag: PermissionFlags.MuteUser,
          handler: ([user]: string[]) =>
            actions.mute(parseUserName(user)).then(() =>
              toastSuccess({
                title: 'User muted',
                description: `${user} has been muted`,
              }),
            ),
        },
        {
          icon: MuteIcon,
          command: 'unmute',
          args: ['user'],
          description: <span>/unmute @user</span>,
          flag: PermissionFlags.UnMuteUser,
          handler: ([user]: string[]) =>
            actions.removeMute(parseUserName(user)).then(() =>
              toastSuccess({
                title: 'User unmuted',
                description: `${user} has been unmuted`,
              }),
            ),
        },
        {
          icon: RecentIcon,
          command: 'timeout',
          args: ['user', 'seconds'],
          description: <span>/timeout @user [seconds]</span>,
          flag: PermissionFlags.TimeoutUser,
          handler: ([user, seconds]: string[]) =>
            actions.timeout(parseUserName(user), undefined, seconds).then(() =>
              toastSuccess({
                title: 'User timeout added',
                description: `${user} has been timed out for ${seconds} seconds`,
              }),
            ),
        },
        {
          icon: RecentIcon,
          command: 'untimeout',
          args: ['user'],
          description: <span>/untimeout @user</span>,
          flag: PermissionFlags.UnTimeoutUser,
          handler: ([user]: string[]) =>
            actions.removeTimeout(parseUserName(user)).then(() =>
              toastSuccess({
                title: 'User timeout removed',
                description: `${user} is no longer timed out`,
              }),
            ),
        },
      ]
        .filter(
          ({ flag }) =>
            !flag ||
            isCommandAvailable(userPermissions, flag as unknown as BigInteger),
        )
        .map(cmd => ({ ...cmd, id: cmd.command, display: cmd.command })),
    [onRainOpen, openTipModal, actions, userPermissions],
  );
};
