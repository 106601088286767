import { Grid } from '@chakra-ui/react';
import { Loading } from 'components/loading';
import dayjs from 'dayjs';
import { useLayout } from 'hooks/use-layout';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getVersusGamesList,
  getVersusGamesLoaded,
  getVersusHistory,
} from 'services/games/modules/versus';
import { Game } from './components/game';

export const GamesList = () => {
  const games = useSelector(getVersusGamesList);
  const history = useSelector(getVersusHistory);
  const [, forceUpdate] = useState({});
  const { gap } = useLayout();
  const isLoaded = useSelector(getVersusGamesLoaded);

  useEffect(() => {
    const interval = setInterval(() => {
      forceUpdate({});
    }, 20000);

    return () => clearInterval(interval);
  }, []);

  if (!isLoaded) {
    return <Loading pos="relative" my="20" mx="auto" />;
  }

  return (
    <Grid
      w="full"
      userSelect="none"
      sx={{
        [`@media screen and (min-width: ${gap + 350}px)`]: {
          gridTemplateColumns: 'repeat(1, 1fr)',
        },
        [`@media screen and (min-width: ${gap + 700}px)`]: {
          gridTemplateColumns: 'repeat(1, 1fr)',
        },
        [`@media screen and (min-width: ${gap + 1050}px)`]: {
          gridTemplateColumns: 'repeat(2, 1fr)',
        },
      }}
      columnGap="3"
      rowGap="5"
    >
      {games.map(game => (
        <Game key={game.gameId} game={game} />
      ))}
      {history
        .filter(g => dayjs(g.endTime * 1000).add(2, 'minutes') > dayjs())
        .slice(0, 10)
        .map(game => (
          <Game key={game.gameId} game={game} isFinished />
        ))}
    </Grid>
  );
};
