/* eslint-disable no-inner-declarations */
import { IS_PIP_AVAILABLE } from 'constants/general';
import { useLayoutEffect, useRef, useState } from 'react';

export const usePip = ({
  id,
  hasTabListener = false,
  isReady = true,
}: {
  id: string;
  hasTabListener?: boolean;
  isReady?: boolean;
}) => {
  const [isPiP, setIsPiP] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>();
  useLayoutEffect(() => {
    if (IS_PIP_AVAILABLE && isReady) {
      let container: HTMLDivElement | null = null;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let pipWindow: any = null;

      const onLeavePiP = () => {
        setIsPiP(false);

        if (pipWindow) {
          const g = pipWindow.document.querySelector(`#${id}`);
          if (container) {
            container.append(g);
            container.classList.remove('pip');
          }

          pipWindow.close();

          pipWindow = null;
          container = null;
        }
      };

      const enterPiP = async () => {
        setIsPiP(true);

        try {
          const g = document.querySelector(`#${id}`);

          if (!g) {
            return;
          }

          container = g.parentNode as HTMLDivElement;
          container.classList.add('pip');

          const pipOptions = {
            initialAspectRatio: g.clientWidth / g.clientHeight,
            lockAspectRatio: true,
            copyStyleSheets: true,
          };

          pipWindow =
            // @ts-ignore
            await window.documentPictureInPicture.requestWindow(pipOptions);

          // console.log('pipWindow', pipWindow);

          // @ts-ignore
          [...document.styleSheets].forEach(styleSheet => {
            try {
              const cssRules = [...styleSheet.cssRules]
                .map(rule => rule.cssText)
                .join('');
              const style = document.createElement('style');

              style.textContent = cssRules;
              pipWindow.document.head.appendChild(style);
            } catch (e) {
              const link2 = document.createElement('link');

              link2.rel = 'stylesheet';
              link2.type = styleSheet.type;
              link2.media = styleSheet.media;
              link2.href = styleSheet.href;
              pipWindow.document.head.appendChild(link2);
            }
          });

          pipWindow.document.body.append(g);

          pipWindow.addEventListener('unload', onLeavePiP, {
            once: true,
          });
        } catch (e) {
          console.warn('catch enter Pip', e);
          setIsPiP(false);
        }
      };

      if (buttonRef.current) {
        buttonRef.current.addEventListener('click', () => {
          if (!pipWindow) {
            enterPiP();
          } else {
            onLeavePiP();
          }
        });
      }

      if (hasTabListener) {
        const handleVisibilityChange = () => {
          if (document.visibilityState === 'hidden') {
            enterPiP();
          } else {
            onLeavePiP();
          }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
          document.removeEventListener(
            'visibilitychange',
            handleVisibilityChange,
          );
        };
      }
    }

    return undefined;
  }, [id, hasTabListener, isReady]);

  return { isPiP, buttonRef };
};
