import { createAction } from 'store';
import * as api from './api';

export const loadQuests = createAction(
  'quests/loadQuests',
  () => () => api.loadQuests(),
);

export const loadInactiveQuests = createAction(
  'quests/loadInactiveQuests',
  params => () => api.loadInactiveQuests(params),
  params => params,
);
