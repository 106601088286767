/* eslint-disable max-lines */
import { Box, Flex, IconButton, Text, Tooltip } from '@chakra-ui/react';
import { Content } from 'components/content';
import { PlayerRow } from 'components/player-row';
import dayjs from 'dayjs';
import { formatCredit } from 'helpers/numeral';
import { convertToOriginal } from 'helpers/url-text';
import {
  CategoriesIcon,
  FavouritesIcon,
  FullscreenIcon,
  HistoryIcon,
  InfoIcon,
  QuestsIcon,
  VIPIcon,
} from 'icons';
import { TheatreModeIcon } from 'icons/theatre-mode-icon';
import { MODAL_TYPE, useModal } from 'modals';
import { getFriendsExistsList } from 'modules/chat';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { HtmlContent } from 'services/cms/components/html-content';
import { getQuestsIsLoaded, loadQuests } from 'services/games/modules/quests';
import {
  addFavorites,
  getSlotsGame,
  getSlotsGameQuests,
  getSlotsGameSettings,
  getSlotsGameSettingsIsLoaded,
  getSlotsIsFavoriteGame,
  getSlotsIsFavoritesLoaded,
  getSlotsIsFriendsPopularLoaded,
  loadFavorites,
  loadGameSettings,
  loadGameWagersStats,
  loadPopularWithFriends,
  removeFavorites,
} from 'services/games/modules/slots';
import { ProvidersSlider } from 'services/games/pages/casino/components/providers-slider';
import { SlotsSlider } from 'services/games/pages/casino/components/slots-slider';
import { Wagers } from 'services/games/pages/casino/components/wagers';
import { getUserIsAuthorized } from 'services/user/modules/user';
import { useActions } from 'store';
import { toastSuccess } from 'toasts';
import { UserType } from 'types';
import { Skeleton } from 'uikit/skeleton';
import { Table } from 'uikit/table';

type Props = {
  gameId: string;
  handlerId: number;
  rightContent?: React.ReactNode;
  children: React.ReactNode;
  onGameClick?: (id: string) => void;
  historyElement?: React.ReactNode;
  hasFullScreen?: boolean;
  hasTheatreMode?: boolean;
  isTheatreMode?: boolean;
  handleTheatreMode?: () => void;
  onMulti: () => void;
  isLoaded?: boolean;
};

export const GameLayout: React.FC<Props> = ({
  gameId,
  handlerId,
  rightContent,
  children,
  onGameClick,
  onMulti,
  historyElement,
  hasFullScreen = true,
  isLoaded = true,
  hasTheatreMode = false,
  isTheatreMode,
  handleTheatreMode,
}) => {
  const [tab, setTab] = useState(0);
  const [stats, setStats] = useState<{
    bigWins: {
      user: UserType;
      date: string;
      wagerAmount: number;
      winAmount: number;
    }[];
  } | null>(null);
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const fields = [
    {
      key: 'player',
      title: t('wagers.player'),
      textAlign: 'left' as const,
      minWidth: '120px',
    },
    {
      key: 'wager',
      title: t('wagers.wager'),
      textAlign: 'left' as const,
      display: { base: 'none', lg: 'table-cell' },
    },
    {
      key: 'multiplier',
      title: t('wagers.multiplier'),
      textAlign: 'center' as const,
      display: { base: 'none', lg: 'table-cell' },
    },
    {
      key: 'payout',
      title: t('wagers.payout'),

      textAlign: 'right' as const,
    },
  ];

  const actions = useActions({
    loadPopularWithFriends,
    loadGameWagersStats,
    loadQuests,
    addFavorites,
    removeFavorites,
    loadFavorites,
    loadGameSettings,
  });

  const isAuthorized = useSelector(getUserIsAuthorized);
  const game = useSelector(getSlotsGame(gameId));
  const isPopularLoaded = useSelector(getSlotsIsFriendsPopularLoaded);
  const friends = useSelector(getFriendsExistsList);
  const questsSelector = useMemo(
    () => getSlotsGameQuests(gameId, handlerId),
    [gameId, handlerId],
  );
  const gameSettings = useSelector(getSlotsGameSettings(gameId));
  const gameSettingsIsLoaded = useSelector(getSlotsGameSettingsIsLoaded);
  const quests = useSelector(questsSelector);
  const isQuestsLoaded = useSelector(getQuestsIsLoaded);

  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false);

  useEffect(() => {
    if (!isPopularLoaded && isAuthorized) {
      actions.loadPopularWithFriends();
    }
  }, [actions, isPopularLoaded, friends.length, isAuthorized]);

  useEffect(() => {
    if (!isQuestsLoaded) {
      actions.loadQuests();
    }
  }, [actions, isQuestsLoaded]);

  useEffect(() => {
    if (!gameSettingsIsLoaded) {
      actions.loadGameSettings();
    }
  }, [actions, gameSettingsIsLoaded]);

  useLayoutEffect(() => {
    setTab(0);
  }, [gameId]);

  const { onOpen: openAuthModal } = useModal(MODAL_TYPE.AUTH);
  const isFavoritesLoaded = useSelector(getSlotsIsFavoritesLoaded);
  const isFavorite = useSelector(getSlotsIsFavoriteGame(gameId));

  useEffect(() => {
    if (!isFavoritesLoaded && isAuthorized) {
      actions.loadFavorites();
    }
  }, [actions, isFavoritesLoaded, isAuthorized]);

  const handleFullScreen = () => {
    const element = document.getElementById('fullscreen') as HTMLElement & {
      mozRequestFullScreen(): Promise<void>;
      webkitRequestFullscreen(): Promise<void>;
      msRequestFullscreen(): Promise<void>;
    };
    if (element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  };

  const handleFavorite = () => {
    if (!isAuthorized) {
      openAuthModal();
    } else if (isFavorite) {
      actions.removeFavorites(game.id, handlerId);
    } else {
      actions.addFavorites(game.id, handlerId);
      toastSuccess({
        title: t('casino.game-added-favorites'),
        description: t('casino.game-added-favorites-description', {
          game: game.title,
        }),
      });
    }
  };

  const data = useMemo(() => {
    if (!stats) {
      return [];
    }

    const list = stats.bigWins;

    return list.map(({ user, date: timestamp, wagerAmount, winAmount }) => {
      const multiplier = winAmount / wagerAmount;

      return {
        tip: (
          <Flex align="center" gap="1">
            <Text as="span" color="vanilla-text">
              {user?.name || 'Hidden'}
            </Text>
            <Text color="candy-floss-text">•</Text>
            <Text as="span" color="candy-floss-text">
              {`${formatCredit(multiplier)}`}x
            </Text>
          </Flex>
        ),
        player: (
          <Flex align="center" gap="2">
            <PlayerRow user={user} hasAvatar={!!user?.icon} />
          </Flex>
        ),
        time: dayjs(Number(timestamp) * 1000).fromNow(),
        wager: `$${formatCredit(wagerAmount)}`,
        multiplier: (
          <Text color="candy-floss-text">{formatCredit(multiplier)}x</Text>
        ),
        payout: (
          <Text color="sherbet-green.200">${formatCredit(winAmount)}</Text>
        ),
      };
    });
  }, [stats]);

  const tipLeaderboardValue = data?.[0]?.tip;

  const tags: { text: string; link?: string }[] =
    isLoaded && game?.tags
      ? [
          ...game.tags.map((tag: string) => ({
            text: t(`casino.tags.${tag}`, tag),
            link: `/casino/tag/${tag}`,
          })),
        ]
      : [];

  if (game?.rtp) {
    tags.push({
      text: t('casino.rtp', { rtp: formatCredit(game.rtp) }),
    });
  }

  if (game?.categories) {
    const list = game.categories;

    list.forEach((category: string) => {
      tags.push({
        text: t(`casino.category.${category}`, convertToOriginal(category)),
        link: `/casino/category/${category}`,
      });
    });
  }

  const tabs = [
    {
      Icon: InfoIcon,
      children: game?.title || '-',
    },
    ...(historyElement
      ? [
          {
            Icon: HistoryIcon,
            children: t('casino-game-fair'),
          },
        ]
      : []),
    ...(data.length
      ? [
          {
            Icon: VIPIcon,
            children: tipLeaderboardValue,
          },
        ]
      : []),
    ...(quests.length
      ? [
          {
            Icon: QuestsIcon,
            tip: quests.length,
            children: t('casino.quests'),
          },
        ]
      : []),
  ];

  useEffect(() => {
    (async () => {
      // if (isLeaderboardTabOpened) {
      setStats(await actions.loadGameWagersStats(gameId, handlerId));
      // }
    })();
  }, [actions, gameId, handlerId]);

  let description: React.ReactNode = (
    <Text
      color="candy-floss-text"
      noOfLines={isDescriptionExpanded ? undefined : 1}
    >
      Sherbet is an all-new cryptocurrency gambling experience with an
      ever-growing catalog of games, including several by{' '}
      {t(`casino.provider.${game?.provider}`)}, such as {game?.title}. Play our
      games for free using Fun Play, or sign up and start placing real bets
      using our range of supported cryptocurrencies, including Bitcoin,
      Litecoin, Ethereum, and more. What are you waiting for? Join the fun
      today!
    </Text>
  );

  if (game?.description) {
    description = (
      <Text
        color="candy-floss-text"
        noOfLines={isDescriptionExpanded ? undefined : 1}
      >
        {game?.description}
      </Text>
    );
  }

  if (gameSettings) {
    const settingDescription =
      gameSettings.description[language] || gameSettings.description.en;

    if (settingDescription) {
      description = (
        <Box
          maxH={isDescriptionExpanded ? undefined : '40px'}
          overflow="hidden"
          noOfLines={isDescriptionExpanded ? undefined : 1}
        >
          <HtmlContent content={settingDescription} />
        </Box>
      );
    }
  }

  return (
    <Content>
      {children}
      <Box w="full" maxW="6xl" mx="auto" flexGrow="1">
        <Flex flexDirection="column" gap="7" mt="5">
          <Flex gap="2" px="5" justifyContent="space-between" w="full">
            <Flex align="center" gap="5">
              {hasFullScreen && (
                <Tooltip
                  gutter={10}
                  hasArrow
                  fontSize="md"
                  rounded="md"
                  placement="top"
                  bg="vanilla-text"
                  fontWeight="500"
                  py="1"
                  px="2"
                  color="toffee-base"
                  label={t('casino-game-fullscreen')}
                >
                  <IconButton
                    aria-label="Fullscreen"
                    onClick={handleFullScreen}
                    size="sm"
                    minW="0"
                    variant="link"
                    color="candy-floss-text"
                    icon={<FullscreenIcon />}
                  />
                </Tooltip>
              )}
              {hasTheatreMode && (
                <Tooltip
                  gutter={10}
                  hasArrow
                  fontSize="md"
                  rounded="md"
                  placement="top"
                  bg="vanilla-text"
                  fontWeight="500"
                  py="1"
                  px="2"
                  color="toffee-base"
                  label="Theatre Mode"
                >
                  <IconButton
                    aria-label="Theatre Mode"
                    onClick={handleTheatreMode}
                    size="sm"
                    minW="0"
                    variant="link"
                    color={
                      isTheatreMode ? 'sherbet-green.200' : 'candy-floss-text'
                    }
                    icon={<TheatreModeIcon />}
                  />
                </Tooltip>
              )}
              {onMulti && (
                <Tooltip
                  gutter={10}
                  hasArrow
                  fontSize="md"
                  rounded="md"
                  placement="top"
                  bg="vanilla-text"
                  fontWeight="500"
                  py="1"
                  px="2"
                  color="toffee-base"
                  label="Multi View"
                >
                  <IconButton
                    aria-label="Theatre Mode"
                    onClick={onMulti}
                    size="sm"
                    minW="0"
                    variant="link"
                    icon={<CategoriesIcon />}
                  />
                </Tooltip>
              )}
              <Tooltip
                gutter={10}
                hasArrow
                fontSize="md"
                rounded="md"
                placement="top"
                bg="vanilla-text"
                fontWeight="500"
                py="1"
                px="2"
                color="toffee-base"
                label={
                  isFavorite
                    ? t('casino.remove-favorites')
                    : t('casino.favorite-game')
                }
              >
                <IconButton
                  aria-label="Favourite"
                  onClick={handleFavorite}
                  size="sm"
                  minW="0"
                  variant="link"
                  rounded="full"
                  color={isFavorite ? '#F09B47' : 'candy-floss-text'}
                  icon={<FavouritesIcon />}
                />
              </Tooltip>
            </Flex>
            {rightContent}
          </Flex>
        </Flex>
      </Box>
      <Box w="full" maxW="6xl" mx="auto" flexGrow="1">
        <Flex p="5" flexDirection="column" gap="7">
          {tab === 0 && (
            <Content>
              <Box
                bg="sugar-dust"
                p="5"
                rounded="xl"
                _hover={{ opacity: 0.9 }}
                cursor="pointer"
                onClick={() => setDescriptionExpanded(!isDescriptionExpanded)}
                pos="relative"
              >
                <Skeleton isLoaded={isLoaded}>{description}</Skeleton>
              </Box>
            </Content>
          )}
          {tabs[tab].Icon === HistoryIcon && historyElement}
          <Content>
            <Table
              key={tab}
              fields={fields}
              data={data}
              isLoading={!stats}
              loadingSkeletonRows={3}
            />
          </Content>
          <SlotsSlider />
          <ProvidersSlider />
        </Flex>
      </Box>
      <Wagers />
    </Content>
  );
};
