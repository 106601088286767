import { createSelector } from '@reduxjs/toolkit';
import bigInt from 'big-integer';
import uniq from 'lodash.uniq';
import { getSlotsGamesData } from 'services/games/modules/slots';
import { KYC_STATUSES } from './constants';
import { UserType } from './types';

type StateType = {
  user: UserType;
};

export const getUserIsAuthorized = (state: StateType) =>
  state.user.isAuthorized;

export const getUserIsAuthorizedReady = (state: StateType) =>
  state.user.isAuthorizedReady;

export const getUserPermissionFlags = createSelector(
  state => state.user.profile?.permissionFlags,
  flags => bigInt(flags),
);

export const getUserAccountFlags = createSelector(
  state => state.user.profile?.accountFlags,
  flags => bigInt(flags),
);
export const getUser = (state: StateType) => state.user.profile;

export const getUserChatColor = (state: StateType) =>
  state.user.profile?.chatColor;

export const getUserSettingsFlags = createSelector(
  state => state.user.profile?.settingFlags,
  flags => bigInt(flags),
);

export const getUserKYCFlags = (state: StateType) =>
  state.user.profile?.kycStatusFlags;

export const getUserRequiredVerify = (state: StateType) => {
  const flags = getUserKYCFlags(state);
  if (
    flags &&
    [
      KYC_STATUSES.REQUIRED,
      KYC_STATUSES.PENDING,
      KYC_STATUSES.DENIED,
      KYC_STATUSES.DUPLICATED,
    ].includes(flags)
  ) {
    return true;
  }
  return false;
};

export const getUserSettingsLoading = (state: StateType) =>
  state.user.settingsLoading;

export const getViewUser = (state: StateType) => state.user.viewUser;

export const getUserId = (state: StateType) => state.user.profile?.id || '';

export const getUserName = (state: StateType) => state.user.profile?.name || '';

export const getUserEmail = (state: StateType) => state.user.profile?.email;

export const getUserStag = (state: StateType) => state.user.profile?.stag;

export const getUserFreePackageDisabled = (state: StateType) =>
  state.user.profile?.freePackageDisabled;

export const getUserWelcomeDepositBonusStatus = (state: StateType) =>
  state.user.profile?.welcomeDepositBonusStatus;

export const getUserEmailConfirmed = (state: StateType) =>
  state.user.profile?.emailConfirmed;

export const getUserLevel = (state: StateType) =>
  state.user.profile?.levelInfo?.level || 0;

export const getUserLevelInfo = (state: StateType) =>
  state.user.profile?.levelInfo;

export const getUserReloadsInfo = (state: StateType) =>
  state.user.profile?.reloadsInfo;

export const getUserRakebackInfo = (state: StateType) =>
  state.user.profile?.rakebackInfo;

export const getUserBonusesList = createSelector(
  (state: StateType) => state.user.profile?.bonusBalances,
  list =>
    (list || []).filter(item => {
      if (!item.canTransfer && item.status === 4) {
        return false;
      }

      return ![5, 6].includes(item.status);
    }),
);

export const getUserBonusSession = (state: StateType) =>
  state.user.profile?.activeBalanceType === 1;

export const getUserTotalWagerAmountRequiredOnBonus = (state: StateType) =>
  state.user.profile?.totalWagerAmountRequiredOnBonus;

export const getUserCurrentWagerAmountOnBonus = (state: StateType) =>
  state.user.profile?.currentWagerAmountForBonusWithdraw;

export const getUserBonusBalance = (state: StateType) =>
  state.user.profile?.creditOnBonus || 0;

export const getUserBalance = (state: StateType) => {
  if (getUserBonusSession(state)) {
    return getUserBonusBalance(state);
  }

  return state.user.profile?.credit || 0;
};

export const getUserBonusSessionActive = (state: StateType) =>
  getUserBonusSession(state);

export const getUserRakeback = (state: StateType) =>
  state.user.profile?.rakebackCredit || 0;

export const getUserAvatar = (state: StateType) => state.user.profile?.icon;

export const getUserWinMultiplierForShare = (state: StateType) =>
  state.user.profile?.winMultiplierForShare;

export const getUserIsLoaded = (state: StateType) => state.user.isLoaded;

export const getViewUserIsLoading = (state: StateType) =>
  state.user.viewUserIsLoading;

export const get2FALoading = (state: StateType) => state.user.twoFALoading;

export const get2FA = (state: StateType) => state.user.twoFA;

export const get2FARecoveryCodes = (state: StateType) =>
  state.user.recoveryCodes;

export const getUser2FAEnabled = (state: StateType) =>
  get2FA(state)?.is2faEnabled;

export const getUserUseLevelOverNFT = (state: StateType) =>
  state.user.profile?.useLevelOverNFT;

export const getViewUserStats = (username: string) => (state: StateType) =>
  state.user.viewUserStats[username];

export const getUserIsSelfExcluded = (state: StateType) =>
  state.user.profile?.isSelfExcluded;

export const getUserSelfExcludedDate = (state: StateType) =>
  state.user.profile?.selfExcludeEndDate;

export const getUserSocial = (social: string) => (state: StateType) =>
  state.user.social[social];

export const getUserFreespins = createSelector(
  (state: StateType) => state.user.profile?.freespins,
  list =>
    (list || []).filter(
      ({ status }) => !['played', 'expired', 'cancelled'].includes(status),
    ),
);

export const getUserFreespinsGamesList = createSelector(
  getUserFreespins,
  getSlotsGamesData,
  (spins, games) =>
    uniq(
      spins.filter(spin => spin.status !== 'created').map(spin => spin.games),
    )
      .map(id => games[id])
      .filter(i => i),
);

export const getUserBonusesLength = (state: StateType) =>
  getUserBonusesList(state).length + (getUserRakeback(state) > 0.01 ? 1 : 0);

export const getUserBonusByType = (type: string) => (state: StateType) =>
  getUserBonusesList(state).find(b => b.bonusType === type);

export const getUserBonusByStatus = (status: number) => (state: StateType) =>
  getUserBonusesList(state).find(b => b.status === status);

export const getUserBonusDepositReloadReady = (state: StateType) => {
  const list = getUserBonusesList(state).filter(b => b.isDepositReload);

  if (
    list.length > 0 &&
    list.some(item => item.status === 0 && item.canActivate)
  ) {
    return true;
  }

  return false;
};

export const getUserBonusWelcomeBonusAvailableToDeposit = (
  state: StateType,
) => {
  const bonus = getUserBonusesList(state).find(
    b => b.bonusType === 'Welcome Deposit Bonus',
  );

  if (bonus && bonus.status < 2) {
    return true;
  }

  return false;
};

export const getUserActualFreeSpinBonus = (state: StateType) => {
  const bonuses = getUserBonusesList(state);

  const bonus = bonuses.find(b => b.bonusType === 'Free Spins');

  if (bonus && Number(bonus.createdTime) * 1000 < Date.now() + 1200000) {
    return bonus;
  }

  return false;
};

export const getUserBonusById = (id: string) => (state: StateType) => {
  const bonuses = getUserBonusesList(state);

  return bonuses.find(b => b.id === id);
};

export const getSessionsList = (state: StateType) => state.user.sessions;

export const getUserNfts = (username: string) => (state: StateType) =>
  state.user.userNfts[username];

export const getUserBadge = (state: StateType) => state.user.userBadge;
export const getUserBadges = (state: StateType) => state.user.userBadges;

export const getUserHasMax = (state: StateType) => state.user.profile?.ownsNFT;

export const getUserDepositStatus = (state: StateType) =>
  state.user.depositStatus;

export const getUserAffiliateCampaignDetails = (state: StateType) =>
  state.user.affiliateCampaignDetails;

export const getUserIsAffiliateCampaignDetailsIsLoaded = (state: StateType) =>
  state.user.isAffiliateCampaignDetailsLoaded;
