import { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';

export const AffiliateRedirect = () => {
  const { name } = useParams();
  useEffect(() => {
    if (name) {
      localStorage.setItem('stag', name);
    }
  }, [name]);

  return <Navigate to="/" replace />;
};
