import { Text } from '@chakra-ui/react';
import { PlayerRow } from 'components/player-row';
import { formatCredit } from 'helpers/numeral';
import { RafflesIcon } from 'icons';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyResults } from 'uikit/empty-results';
import { Table } from 'uikit/table';

const prizePoolPercentagesWeekly = [0.4, 0.2, 0.1];
const prizePoolPercentagesDaily = [0.6, 0.1, 0.1, 0.1, 0.1];

const topColors = {
  1: '#E6CA38',
  2: '#D9D9D9',
  3: '#EF8E3D',
};

export const RaceTable = ({
  entries,
  isMultiplier,
  currentPrizePool,
  maxWinnersPerRound,
}) => {
  const { t } = useTranslation();
  const fields = [
    {
      key: 'place',
      title: t('race.place'),
      textAlign: 'left',
      display: { base: 'none', md: 'table-cell' },
    },
    {
      key: 'player',
      title: t('race.player'),
      width: 'full',
      minWidth: '120px',
      textAlign: 'left',
    },
    {
      key: 'score',
      title: isMultiplier ? t('race.multiplier') : t('race.points'),
      textAlign: 'right',
      minWidth: '120px',
    },
    { key: 'prize', title: t('race.prize'), textAlign: 'right' },
  ];

  const prizePoolPercentages = isMultiplier
    ? prizePoolPercentagesDaily
    : prizePoolPercentagesWeekly;

  const data = useMemo(() => {
    const listOfData = [];
    for (let i = 0; i < Math.max(maxWinnersPerRound, entries.length); i += 1) {
      const item = entries[i];

      if (item) {
        const { user, score, prize, rank } = item;

        listOfData[i] = {
          place: (
            <Text align="start" color="candy-floss-text">
              {t('number.ordinal', {
                count: rank,
                ordinal: true,
              })}
            </Text>
          ),
          player: (
            <PlayerRow
              user={user}
              hasAvatar={!!user?.icon}
              isProfileAvailable={false}
            />
          ),
          score: isMultiplier
            ? `${formatCredit(score, {
                minimumFractionDigits: 0,
              })}x`
            : formatCredit(score),
          prize: (
            <Text color="candy-floss-text">
              $
              {isMultiplier || prize === '0'
                ? formatCredit(prize)
                : formatCredit(
                    currentPrizePool * (prizePoolPercentages[i] || 0.042857),
                  )}
            </Text>
          ),
        };
      } else {
        listOfData[i] = {
          place: (
            <Text align="start" color="candy-floss-text">
              {t('number.ordinal', {
                count: i + 1,
                ordinal: true,
              })}
            </Text>
          ),
          player: '-',
          multiplier: '-',
          prize: (
            <Text color="candy-floss-text">
              $
              {formatCredit(
                currentPrizePool * (prizePoolPercentages[i] || 0.042857),
              )}
            </Text>
          ),
        };
      }
    }

    return listOfData;
  }, [
    entries,
    t,
    isMultiplier,
    currentPrizePool,
    maxWinnersPerRound,
    prizePoolPercentages,
  ]);

  if (!data.length) {
    return (
      <EmptyResults
        icon={RafflesIcon}
        title={t('race.empty')}
        description={t('race.empty-description')}
      />
    );
  }

  return <Table fields={fields} data={data} />;
};
