/* eslint-disable react/no-unescaped-entities */
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { CountdownExpire } from 'components/countdown-expire';
import { formatCredit } from 'helpers/numeral';
import { TransactionsIcon } from 'icons';
import capitalize from 'lodash.capitalize';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSlotsGamesData } from 'services/games/modules/slots';
import { getUserAffiliateCampaignDetails } from 'services/user/modules/user';
import { ConfirmButton } from 'uikit/confirm-button';
import { Link } from 'uikit/link';
import { NotificationTip } from 'uikit/notification-tip';

export const WelcomePackage = ({ bonus }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const details = useSelector(getUserAffiliateCampaignDetails);
  const games = useSelector(getSlotsGamesData);

  const handleLoading = action => async () => {
    setIsLoading(true);
    try {
      await action();
    } finally {
      setIsLoading(false);
    }
  };

  let step = 1;

  if (bonus.status > 1) {
    step = 2;
  }

  if (bonus.status > 3) {
    step = 3;
  }

  const status = bonus.subLevel + 1; // frist/second/third bonus

  let content = (
    <Flex align="center" gap="2">
      {bonus.status === 1 ? (
        <>
          <Button as={Link} to="?right=wallet" colorScheme="purple">
            {t('wallet.buy')}
          </Button>
          <Button
            rounded="full"
            onClick={() => handleLoading(bonus.deactivateAction)()}
            isLoading={isLoading}
          >
            {t('bonus.deactivate')}
          </Button>
        </>
      ) : (
        <Button
          colorScheme="purple"
          onClick={() => handleLoading(bonus.activateAction)()}
          isLoading={isLoading}
        >
          {t('bonus.activate')}
        </Button>
      )}
    </Flex>
  );

  if (step === 2) {
    content = bonus.wagerRequirementData && (
      <Flex align="center" gap="2">
        <Button rounded="full" leftIcon={<TransactionsIcon />}>
          {bonus.wagerRequirement}
        </Button>
        <ConfirmButton
          colorScheme="danger"
          confirmText={t('actions.confirm')}
          isLoading={isLoading}
          isForce
          onClick={handleLoading(bonus.cancelAction)}
        >
          Forfeit
        </ConfirmButton>
      </Flex>
    );
  } else if (step === 3) {
    content = (
      <Flex align="center" gap="2">
        <Button
          colorScheme="purple"
          onClick={handleLoading(bonus.transferAction)}
          isLoading={isLoading}
        >
          {t('bonus.complete-bonus')}
        </Button>
      </Flex>
    );
  }

  const lockedAmount = bonus.activatedAmount || bonus.transferrableAmount;

  const nMap = {
    1: t('order.first'),
    2: t('order.second'),
    3: t('order.third'),
  };

  const nMapUpper = {
    1: capitalize(nMap[1]),
    2: capitalize(nMap[2]),
    3: capitalize(nMap[3]),
  };

  if (!details?.depositReloadsPackage) {
    return null;
  }

  const hasSecond = details.depositReloadsPackage.length > 1;
  const hasThird = details.depositReloadsPackage.length > 2;

  const freeSpinPackage = details.freeSpinPackages?.[0];

  const freeSpinsCount =
    freeSpinPackage && freeSpinPackage.spinQuantities.at(-1)?.quantity;

  const activeFreeSpinsCount =
    freeSpinPackage &&
    freeSpinPackage.spinQuantities.find(
      spin => lockedAmount > spin.depositMin && lockedAmount < spin.depositMax,
    )?.quantity;

  const freeSpinsMinDep =
    freeSpinPackage && freeSpinPackage.spinQuantities[0].depositMin;

  const freeSpinsGameCode = freeSpinPackage && freeSpinPackage.gameCode;
  const freeSpinsGameName =
    freeSpinsGameCode && games[freeSpinsGameCode]?.title;

  const getMaxBonus = number => {
    const item = details.depositReloadsPackage[number - 1];
    return item.reloadsMax * item.reloadsPercent;
  };

  const getReloadPercent = number => {
    const item = details.depositReloadsPackage[number - 1];
    return item.reloadsPercent * 100;
  };

  const getTitleText = number => {
    const nameOfNumber = nMapUpper[number];
    const maxBonus = getMaxBonus(number);

    if (number === 1 && freeSpinsCount) {
      return t('welcome-package.title.1', {
        freeSpinsCount,
        maxBonus,
        nameOfNumber,
      });
    }

    return t('welcome-package.title.2', {
      nameOfNumber,
      maxBonus,
    });
  };

  const getDescriptionText = number => {
    const maxBonus = getMaxBonus(number);
    const nameOfNumber = nMapUpper[number];
    if (number === 1 && freeSpinsCount) {
      return t('welcome-package.description.1', {
        freeSpinsMinDep,
        freeSpinsCount,
        freeSpinsGameName,
        maxBonus,
      });
    }

    return t('welcome-package.description.2', {
      nameOfNumber,
      maxBonus,
      percent: getReloadPercent(number),
    });
  };

  const getActiveTitleText = number => {
    const amount = formatCredit(lockedAmount);
    if (number === 1 && activeFreeSpinsCount) {
      return t('welcome-package.active.title.1', {
        activeFreeSpinsCount,
        amount,
      });
    }

    return t('welcome-package.active.title.2', { amount });
  };

  const getActiveDescriptionText = number => {
    const wager = formatCredit(bonus.wagerRequirementData?.[1]);
    const amount = formatCredit(lockedAmount);
    if (bonus.status > 3) {
      return t(
        'Your bonus has been wagered successfully and can be transferred to real balance.',
      );
    }
    if (number === 1 && activeFreeSpinsCount) {
      return t('welcome-package.active.description.1', {
        amount,
        wager,
      });
    }

    return t('welcome-package.active.description.2', {
      amount,
      wager,
    });
  };

  return (
    <Box p="5" pt="0" borderBottom="1px solid" borderColor="truffle-border">
      <Flex flexDirection="column" rounded="xl">
        <Flex flexDirection="column">
          <Link to="/promotions/welcome-package" _hover={{ opacity: 0.9 }}>
            <Box
              bgImage="https://i.imgur.com/wPGv2DI.png"
              bgColor="sugar-dust"
              bgPos="center"
              bgSize="cover"
              rounded="xl"
              w="full"
              h="32"
              pos="relative"
              mb="2"
            >
              <NotificationTip
                notifications={t('bonus-popup.deposit-bonus')}
                bg="rgb(0, 131, 138)"
                position="absolute"
                top="3"
                color="vanilla-text"
                left="3"
              />
            </Box>
          </Link>
          <Flex align="center" justifyContent="space-between" mb="1">
            <Text color="candy-floss-text">
              {t('bonus.expire-in')} <CountdownExpire date={bonus.endTime} /> •
              Wagers: {bonus.wager}
            </Text>
          </Flex>
        </Flex>
      </Flex>

      {status === 1 && (
        <Box>
          {status === 1 && step === 1 && (
            <Box>
              <Flex align={{ base: 'start', md: 'center' }} gap="3" mb="3">
                <Box>
                  <Text fontWeight="500" mb="1">
                    First Welcome Package Bonus
                  </Text>
                  <Text
                    color="candy-floss-text"
                    display={status > 1 ? 'none' : undefined}
                  >
                    {getDescriptionText(1)}
                  </Text>
                </Box>
              </Flex>
              {status === 1 && (step === 1 ? content : null)}
            </Box>
          )}
          {status === 1 && step >= 2 && (
            <Box>
              <Flex align={{ base: 'start', md: 'center' }} gap="3">
                <Box>
                  <Text fontWeight="500" mb="1">
                    {getActiveTitleText(1)}
                  </Text>
                  <Text color="candy-floss-text" mb="3">
                    {getActiveDescriptionText(1)}
                  </Text>
                </Box>
              </Flex>
              {content}
            </Box>
          )}
        </Box>
      )}

      {status === 2 && (
        <Box>
          {status === 2 && step === 1 && (
            <Box>
              <Flex align={{ base: 'start', md: 'center' }} gap="3" mb="3">
                <Box>
                  <Text fontWeight="500" mb="1">
                    Second Welcome Package Bonus
                  </Text>
                  <Text
                    color="candy-floss-text"
                    display={status > 2 ? 'none' : undefined}
                  >
                    {getDescriptionText(2)}
                  </Text>
                </Box>
              </Flex>
              {status === 2 && (step === 1 ? content : null)}
            </Box>
          )}
          {status === 2 && step >= 2 && (
            <Box>
              <Flex align={{ base: 'start', md: 'center' }} gap="3">
                <Box>
                  <Text fontWeight="500" mb="1">
                    {getActiveTitleText(1)}
                  </Text>
                  <Text color="candy-floss-text" mb="3">
                    {getActiveDescriptionText(1)}
                  </Text>
                </Box>
              </Flex>
              {content}
            </Box>
          )}
        </Box>
      )}

      {status === 3 && (
        <Box>
          {status === 3 && step === 1 && (
            <Box>
              <Flex align={{ base: 'start', md: 'center' }} gap="3" mb="3">
                <Box>
                  <Text fontWeight="500" mb="1">
                    Third Welcome Package Bonus
                  </Text>
                  <Text
                    color="candy-floss-text"
                    display={status > 3 ? 'none' : undefined}
                  >
                    {getDescriptionText(3)}
                  </Text>
                </Box>
              </Flex>
              {status === 3 && (step === 1 ? content : null)}
            </Box>
          )}
          {status === 3 && step >= 2 && (
            <Box>
              <Flex align={{ base: 'start', md: 'center' }} gap="3">
                <Box>
                  <Text fontWeight="500" mb="1">
                    {getActiveTitleText(3)}
                  </Text>
                  <Text color="candy-floss-text" mb="3">
                    {getActiveDescriptionText(3)}
                  </Text>
                </Box>
              </Flex>
              {content}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
