import { LayoutContext } from 'contexts/layout-context';
import { useMobile } from 'hooks/use-mobile';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { getUserIsAuthorized } from 'services/user/modules/user';

const gaps = {
  '1-1': 330 + 240, // 340
  '1-0': 240,
  '0-1': 330, // 340
  '0-0': 0,
};

export const useLayout = () => {
  const isMobile = useMobile();
  const isAuthorized = useSelector(getUserIsAuthorized);
  const _layout = useContext(LayoutContext);
  const [left] = _layout.split('-') || '0-0';
  const layout = !isAuthorized ? `${left}-0` : _layout;

  if (isMobile) {
    return { layout: '0-0', isMobile, gap: 0 };
  }

  // console.log(gaps[layout as keyof typeof gaps]);

  return {
    layout,
    isMobile,
    gap: gaps[layout as keyof typeof gaps] || 0,
  };
};
