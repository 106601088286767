import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { formatCredit } from 'helpers/numeral';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  getUserId,
  getUserRakeback,
  getUserRakebackInfo,
  redeemRakeback,
} from 'services/user/modules/user';
import { useActions } from 'store';
import { toastError, toastSuccess } from 'toasts';

export const RakeBackCard = () => {
  const [isFlipped, setIsFlipped] = useState(false);
  const actions = useActions({
    redeemRakeback,
  });
  const { t } = useTranslation();

  const userRakebackInfo = useSelector(getUserRakebackInfo);

  const userId = useSelector(getUserId);
  const rakeback = useSelector(getUserRakeback);

  const totalRakebackAmount = formatCredit(userRakebackInfo.totalAmount * 100);
  const [isRedeemInProgress, setIsRedeemInProgress] = useState(false);

  const handleRedeemRakeback = async () => {
    if (rakeback) {
      setIsRedeemInProgress(true);
      try {
        await actions.redeemRakeback(userId);
        toastSuccess({
          title: t('authorized-section.rakeback-claimed'),
          description: t('authorized-section.rakeback-claimed-description', {
            rakeback: formatCredit(rakeback),
          }),
        });
      } catch (errors) {
        toastError(errors);
      }
      setIsRedeemInProgress(false);
    }
  };

  const rakebackContent = `${totalRakebackAmount}%`;

  const buttonLabel =
    rakeback > 0
      ? `${t('actions.claim')} $${formatCredit(rakeback)}`
      : t('actions.cliam-now');

  return (
    <Flex
      align="center"
      justifyContent="space-between"
      borderBottom="1px solid"
      borderColor="truffle-border"
      p="5"
      gap="5"
      py="3"
      mb="5"
    >
      <Box>
        <Text fontWeight="500">{t('authorized-section.rakeback')}</Text>
        <Text color="candy-floss-text">
          {t('authorized-section.rakeback-level')} {rakebackContent}
        </Text>
      </Box>
      <Button
        colorScheme="purple"
        rounded="full"
        onClick={handleRedeemRakeback}
        isDisabled={rakeback < 0.01}
        isLoading={isRedeemInProgress}
      >
        {buttonLabel}
      </Button>
    </Flex>
  );
};
