/* eslint-disable max-lines */
import {
  Box,
  Button,
  Flex,
  IconButton,
  Radio,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Content } from 'components/content';
import { LANGS_LIST } from 'constants/general';
import { removeIntercomToken, removeToken } from 'helpers/client';
import { applyLangToUrl, getPathnameWithoutLang } from 'helpers/lang';
import { useSupport } from 'hooks/use-support';
import { t } from 'i18next';
import {
  AffiliateIcon,
  BaccaratIcon,
  BlackjackIcon,
  BlogIcon,
  BonusBuyIcon,
  CalendarDayIcon,
  CasinoIcon,
  CategoriesIcon,
  FavouritesIcon,
  GameShowsIcon,
  LiveCasinoIcon,
  LobbyIcon,
  LogoutIcon,
  MenuIcon,
  NFTIcon,
  OriginalsIcon,
  PlaylistsIcon,
  PreloaderIcon,
  ProfileIcon,
  PromotionsIcon,
  QuestsIcon,
  RafflesIcon,
  RainIcon,
  RecentIcon,
  ResponsibleGamblingIcon,
  RouletteIcon,
  SearchIcon,
  SettingsIcon,
  SlotsIcon,
  SupportIcon,
  TableGamesIcon,
  TournamentIcon,
  TransactionsIcon,
  WalletIcon,
} from 'icons';
import { DropsAndWinsIcon } from 'icons/drops-and-wins-icon';
import { MODAL_TYPE, useModal } from 'modals';
import { getUserCountry } from 'modules/handler';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { getQuests } from 'services/games/modules/quests';
import { getSlotsNewReleaseIds } from 'services/games/modules/slots';
import { getUserIsAuthorized, getUserName } from 'services/user/modules/user';
import { Link } from 'uikit/link';
import { SearchInput } from 'uikit/search-input';
import { NavLink } from './components/nav-link';
import { NavMenu } from './components/nav-menu';

export const LeftSidebar = ({
  hasLeft,
  changeLeftLayout = () => {},
  isMobile = false,
}) => {
  const location = useLocation();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState('');
  const isSport = location.pathname.includes('/sports');
  const newReleaseIdsLength = useSelector(getSlotsNewReleaseIds).length;
  const questsLength = useSelector(getQuests).filter(
    quest => !quest.isCompleted && !quest.winner && !quest.endDate,
  ).length;
  const username = useSelector(getUserName);

  const handleToggle = next => () => {
    setToggle(prev => (prev === next ? '' : next));
    if (!hasLeft) {
      changeLeftLayout?.();
    }
  };

  const handleLogout = useCallback(() => {
    window?.Intercom?.('shutdown');
    removeToken();
    removeIntercomToken();
    window.location.href = '/';
  }, []);

  const handleChangeLang = lang => () => {
    i18n.changeLanguage(lang, () => {
      navigate(
        applyLangToUrl(
          `${getPathnameWithoutLang(location.pathname)}${location.search}`,
          i18n.language,
        ),
        { replace: true },
      );
    });
  };

  const { onOpen: onOpenSearch } = useModal(MODAL_TYPE.GLOBAL_SEARCH);
  const { toggle: toggleSupport } = useSupport();

  const isAuthorized = useSelector(getUserIsAuthorized);

  const isCasinoActive =
    location.pathname === '/' || location.pathname.startsWith('/casino');
  const isNftActive = location.pathname === '/nft';

  const country = useSelector(getUserCountry);

  let w = '64px';

  if (isMobile) {
    w = 'full';
  } else if (hasLeft) {
    w = '240px';
  }

  const currentLang = LANGS_LIST.find(i => i.value === i18n.language);

  return (
    <Flex
      w={w}
      h={isMobile ? '100%' : '100dvh'}
      bg={isMobile ? 'rgb(23 24 36)' : 'rgb(23 24 36 / 90%)'}
      pos="sticky"
      top="0"
      flexShrink="0"
      flexDirection="column"
    >
      {!isMobile && (
        <Flex
          py="3"
          w="full"
          gap="5"
          px="2"
          alignItems="center"
          bg="sugar-dust"
          position="relative"
          minH="41px"
          h="41px"
        >
          <IconButton
            pos="absolute"
            aria-label="menu"
            variant="link"
            onClick={() => {
              setToggle('');
              changeLeftLayout();
            }}
            _hover={{ color: 'white' }}
          >
            <MenuIcon />
          </IconButton>
        </Flex>
      )}
      <Flex
        direction="column"
        borderRight="1px solid"
        h="full"
        overflow="hidden"
        borderColor="truffle-border"
      >
        <Box>
          {hasLeft && (
            <Flex
              py="3"
              px={hasLeft ? '5' : '2'}
              gap="2"
              borderBottom="1px solid"
              borderColor="truffle-border"
            >
              <Button
                draggable="false"
                colorScheme="custom"
                border="1px solid"
                rounded="full"
                as={Link}
                to="/"
                leftIcon={<SlotsIcon />}
                color={isCasinoActive ? 'sherbet-purple' : 'candy-floss-text'}
                borderColor={
                  isCasinoActive ? 'sherbet-purple' : 'truffle-border'
                }
                _hover={
                  isCasinoActive ? {} : { borderColor: 'truffle-border-hover' }
                }
              >
                {t('sidebar.casino')}
              </Button>
              <Button
                draggable="false"
                colorScheme="custom"
                border="1px solid"
                rounded="full"
                as={Link}
                to="/nft"
                leftIcon={<NFTIcon />}
                color={isNftActive ? 'sherbet-purple' : 'candy-floss-text'}
                borderColor={isNftActive ? 'sherbet-purple' : 'truffle-border'}
                _hover={
                  isNftActive ? {} : { borderColor: 'truffle-border-hover' }
                }
              >
                {t('sidebar.nft')}
              </Button>
            </Flex>
          )}
          {!hasLeft && (
            <Flex
              gap="2"
              px={hasLeft ? '5' : '2'}
              py="3"
              flexDir="column"
              align="center"
            >
              <Tooltip
                gutter={10}
                hasArrow
                placement="right"
                fontSize="md"
                rounded="md"
                bg="vanilla-text"
                fontWeight="500"
                py="1"
                px="2"
                color="toffee-base"
                label={t('sidebar.casino')}
              >
                <IconButton
                  maxH="40px"
                  maxW="40px"
                  w="full"
                  rounded="full"
                  as={Link}
                  to="/"
                  colorScheme={isCasinoActive ? 'purple' : 'undefined'}
                  icon={<SlotsIcon />}
                />
              </Tooltip>
              <Tooltip
                gutter={10}
                hasArrow
                placement="right"
                fontSize="md"
                rounded="md"
                bg="vanilla-text"
                fontWeight="500"
                py="1"
                px="2"
                color="toffee-base"
                label={t('sidebar.nft')}
              >
                <IconButton
                  rounded="full"
                  maxH="40px"
                  maxW="40px"
                  w="full"
                  as={Link}
                  to="/nft"
                  colorScheme={isNftActive ? 'purple' : 'undefined'}
                  icon={<NFTIcon />}
                />
              </Tooltip>
            </Flex>
          )}
        </Box>
        <Content flexGrow="1" overflow="auto" direction="column" key={hasLeft}>
          <Box>
            {!isMobile &&
              (hasLeft ? (
                <Box
                  px="5"
                  py="5"
                  borderBottom="1px"
                  borderColor="truffle-border"
                  onFocus={() => onOpenSearch()}
                >
                  <SearchInput readOnly placeholder="CMD + K" />
                </Box>
              ) : (
                <Flex justifyContent="center" mb="3">
                  <Tooltip
                    gutter={10}
                    hasArrow
                    placement="right"
                    fontSize="md"
                    rounded="md"
                    bg="vanilla-text"
                    fontWeight="500"
                    py="1"
                    px="2"
                    color="toffee-base"
                    label={t('actions.search')}
                  >
                    <IconButton onClick={() => onOpenSearch()} rounded="full">
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                </Flex>
              ))}
            {!isMobile &&
              (hasLeft ? (
                <Box
                  p="4"
                  px="5"
                  mb="3"
                  borderBottom="1px"
                  borderColor="truffle-border"
                >
                  <Flex align="center" gap="3">
                    <Link
                      rounded="xl"
                      _hover={{ opacity: '0.9' }}
                      to="https://x.com/Sherbet/status/1793909123765182959"
                    >
                      <Flex
                        h="40px"
                        w="40px"
                        rounded="xl"
                        bg="sherbet-purple"
                        align="center"
                        justifyContent="center"
                      >
                        <PreloaderIcon h="24px" w="24px" />
                      </Flex>
                    </Link>
                    <Box>
                      <Text fontWeight="500">$SHBT Airdrop</Text>
                      <Text color="candy-floss-text">
                        {t('casino.slider.coming-soon.title')}
                      </Text>
                    </Box>
                  </Flex>
                </Box>
              ) : (
                <Flex justifyContent="center" mb="3">
                  <Link to="https://x.com/Sherbet/status/1793909123765182959">
                    <Tooltip
                      gutter={10}
                      hasArrow
                      placement="right"
                      fontSize="md"
                      rounded="md"
                      bg="vanilla-text"
                      fontWeight="500"
                      py="1"
                      px="2"
                      color="toffee-base"
                      label="$SHBT"
                    >
                      <Flex
                        h="40px"
                        w="40px"
                        rounded="xl"
                        bg="sherbet-purple"
                        align="center"
                        justifyContent="center"
                      >
                        <PreloaderIcon h="24px" w="24px" />
                      </Flex>
                    </Tooltip>
                  </Link>
                </Flex>
              ))}

            <Box mb="3">
              <NavLink to="/" isOpened={hasLeft} icon={<LobbyIcon />}>
                {t('casino.category.lobby')}
              </NavLink>
              <Box
                opacity={isAuthorized ? '1' : '0.5'}
                pointerEvents={isAuthorized ? 'undefined' : 'none'}
              >
                <NavMenu
                  icon={<PlaylistsIcon />}
                  title={t('sidebar.playlists')}
                  hasLeft={hasLeft}
                  onToggle={handleToggle('playlists')}
                  isOpened={toggle === 'playlists'}
                >
                  <NavLink
                    to="/casino/category/favourites"
                    isOpened={hasLeft}
                    icon={<FavouritesIcon />}
                  >
                    {t('casino.category.favorites')}
                  </NavLink>
                  <NavLink
                    to="/casino/category/curated-for-you"
                    isOpened={hasLeft}
                    icon={<RainIcon />}
                  >
                    {t('playlists.daily-mix')}
                  </NavLink>
                  <NavLink
                    to="/playlists"
                    isOpened={hasLeft}
                    icon={<CategoriesIcon />}
                  >
                    {t('actions.browse-all')}
                  </NavLink>
                </NavMenu>
                <NavLink
                  to="/casino/category/recent"
                  isOpened={hasLeft}
                  icon={<RecentIcon />}
                >
                  {t('casino.slider.recently-played.title')}
                </NavLink>
              </Box>
              <NavLink
                to="/casino/quests"
                isOpened={hasLeft}
                icon={<QuestsIcon />}
                tip={28}
              >
                {t('casino.quests')}
              </NavLink>
            </Box>

            <Box mb="3">
              <NavMenu
                icon={<TournamentIcon />}
                title={t('sidebar.tournaments')}
                hasLeft={hasLeft}
                onToggle={handleToggle('tournaments')}
                isOpened={toggle === 'tournaments'}
              >
                <NavLink
                  to="/tournaments/daily-scramble"
                  isOpened={hasLeft}
                  icon={<CalendarDayIcon />}
                >
                  {t('tournaments.daily-scramble')}
                </NavLink>
                <NavLink
                  to="/tournaments/weekly-race"
                  isOpened={hasLeft}
                  icon={<RafflesIcon />}
                >
                  {t('tournaments.weekly-race')}
                </NavLink>
                <NavLink
                  to="/tournaments"
                  isOpened={hasLeft}
                  icon={<CategoriesIcon />}
                >
                  {t('actions.browse-all')}
                </NavLink>
              </NavMenu>
            </Box>

            <Box mb="3">
              <NavLink
                to="/casino/category/originals"
                isOpened={hasLeft}
                icon={<OriginalsIcon />}
              >
                {t('casino.category.originals')}
              </NavLink>
              <NavLink
                to="/casino/category/slots"
                isOpened={hasLeft}
                icon={<SlotsIcon />}
              >
                {t('casino.category.slots')}
              </NavLink>
              <NavLink
                to="/casino/category/new-releases"
                isOpened={hasLeft}
                icon={<RainIcon />}
                tip={newReleaseIdsLength}
              >
                {t('casino.category.new-releases')}
              </NavLink>
              <NavLink
                to="/casino/category/live-casino"
                isOpened={hasLeft}
                icon={<LiveCasinoIcon />}
              >
                {t('casino.category.live-casino')}
              </NavLink>
              <NavLink
                to="/casino/category/bonus-buy"
                isOpened={hasLeft}
                icon={<BonusBuyIcon />}
              >
                {t('casino.category.bonus-buy')}
              </NavLink>
              <NavLink
                to="/casino/category/game-shows"
                isOpened={hasLeft}
                icon={<GameShowsIcon />}
              >
                {t('casino.category.game-shows')}
              </NavLink>

              <NavLink
                to="/casino/category/table-games"
                isOpened={hasLeft}
                icon={<TableGamesIcon />}
              >
                {t('casino.category.table-games')}
              </NavLink>
              <NavLink
                to="/casino/category/blackjack"
                isOpened={hasLeft}
                icon={<BlackjackIcon />}
              >
                {t('casino.category.blackjack')}
              </NavLink>
              <NavLink
                to="/casino/category/baccarat"
                isOpened={hasLeft}
                icon={<BaccaratIcon />}
              >
                {t('casino.category.baccarat')}
              </NavLink>
              <NavLink
                to="/casino/category/roulette"
                isOpened={hasLeft}
                icon={<RouletteIcon />}
              >
                {t('casino.category.roulette')}
              </NavLink>
            </Box>
            <Box mb="3">
              <NavLink
                to="/casino/providers"
                isOpened={hasLeft}
                icon={<CategoriesIcon />}
              >
                {t('casino.providers')}
              </NavLink>
            </Box>

            <Box mb="3" sx={{ display: isAuthorized ? 'block' : 'none' }}>
              <NavMenu
                icon={<ProfileIcon />}
                title={t('profile.title')}
                hasLeft={hasLeft}
                onToggle={handleToggle('profile')}
                isOpened={toggle === 'profile'}
              >
                <NavLink
                  to="?right=wallet"
                  isOpened={hasLeft}
                  icon={<WalletIcon />}
                >
                  {t('wallet.cashier')}
                </NavLink>
                <NavLink
                  to="?modal=wallet&tab=transactions&subtab=bets"
                  isOpened={hasLeft}
                  icon={<TransactionsIcon />}
                >
                  {t('profile.transactions.title')}
                </NavLink>

                <NavLink
                  to={`?modal=profile&username=${username}&tab=settings`}
                  isOpened={hasLeft}
                  icon={<SettingsIcon />}
                >
                  {t('profile.settings.title')}
                </NavLink>
                <NavLink
                  to={`?modal=profile&username=${username}&tab=affiliates`}
                  isOpened={hasLeft}
                  icon={<AffiliateIcon />}
                >
                  {t('profile.affiliates')}
                </NavLink>
                <Box bg="sherbet-red.100">
                  <NavLink
                    onClick={handleLogout}
                    isOpened={hasLeft}
                    icon={<LogoutIcon />}
                  >
                    {t('authorized-section.logout')}
                  </NavLink>
                </Box>
              </NavMenu>
            </Box>
            <Box mb="3">
              <NavMenu
                icon={<PromotionsIcon />}
                title={t('sidebar.promotions')}
                hasLeft={hasLeft}
                onToggle={handleToggle('promotions')}
                isOpened={toggle === 'promotions'}
              >
                <NavLink
                  to="/promotions/welcome-package"
                  isOpened={hasLeft}
                  icon={<CasinoIcon />}
                >
                  Welcome Package
                </NavLink>
                <NavLink
                  to="/promotions/drops-and-wins"
                  isOpened={hasLeft}
                  icon={<DropsAndWinsIcon />}
                >
                  Drops & Wins
                </NavLink>
                <NavLink
                  to="/promotions"
                  isOpened={hasLeft}
                  icon={<CategoriesIcon />}
                >
                  {t('actions.browse-all')}
                </NavLink>
              </NavMenu>
              <NavLink to="/blog" isOpened={hasLeft} icon={<BlogIcon />}>
                {t('sidebar.blog')}
              </NavLink>
            </Box>
            <Box mb="3">
              <NavLink
                to="/responsible-gambling/sherbet-safe"
                isOpened={hasLeft}
                icon={<ResponsibleGamblingIcon />}
              >
                {t('footer.responsible-gambling')}
              </NavLink>
              <NavLink
                onClick={toggleSupport}
                isOpened={hasLeft}
                icon={<SupportIcon />}
              >
                {t('support.live-support')}
              </NavLink>
              <NavMenu
                icon={<currentLang.icon />}
                title={`${t('footer.language')}: ${currentLang.title}`}
                hasLeft={hasLeft}
                onToggle={handleToggle('langs')}
                isOpened={toggle === 'langs'}
              >
                {LANGS_LIST.map(lang => (
                  <NavLink
                    key={lang.value}
                    onClick={handleChangeLang(lang.value)}
                    isOpened={hasLeft}
                    icon={<lang.icon />}
                    rightIcon={
                      <Radio
                        isChecked={i18n.language === lang.value}
                        onChange={() => handleChangeLang(lang.value)}
                      />
                    }
                  >
                    {lang.title}
                  </NavLink>
                ))}
              </NavMenu>
            </Box>
          </Box>
        </Content>
      </Flex>
    </Flex>
  );
};
