import { Flex } from '@chakra-ui/react';
import { Content } from 'components/content';
import { PreviewGrid } from 'components/preview-grid';
import { PreviewSlider } from 'components/preview-slider';
import { ShowMore } from 'components/show-more';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday'; // Import isToday plugin to check if a date is today
import timezone from 'dayjs/plugin/timezone'; // Import the Timezone plugin for handling timezones
import utc from 'dayjs/plugin/utc'; // Ensure you have imported the UTC plugin
import { QuestsIcon } from 'icons';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { QuestPreview } from 'services/games/components/quest-preview';
import {
  getInactiveQuests,
  getInactiveQuestsIsLoaded,
  getInactiveTotal,
  getQuests,
  getQuestsIsLoaded,
  loadInactiveQuests,
  loadQuests,
} from 'services/games/modules/quests';
import {
  getSlotsGamesData,
  getSlotsIsLoaded,
} from 'services/games/modules/slots';
import { useActions } from 'store';
import { Countdown } from 'uikit/countdown';
import { EmptyResults } from 'uikit/empty-results';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);

export const Quests = () => {
  const [search] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const isInactive = tabIndex === 1;
  const [isLoading, setIsLoading] = useState(false);
  const isLoaded = useSelector(getQuestsIsLoaded);
  const isSlotsLoaded = useSelector(getSlotsIsLoaded);
  const quests = useSelector(getQuests);
  const inactiveQuests = useSelector(getInactiveQuests);
  const isInactiveLoaded = useSelector(getInactiveQuestsIsLoaded);
  const games = useSelector(getSlotsGamesData);
  const actions = useActions({ loadQuests, loadInactiveQuests });
  const total = useSelector(getInactiveTotal);
  const { t } = useTranslation();

  const list = useMemo(() => {
    let items = isInactive ? inactiveQuests : quests;
    if (search) {
      items = items.filter(c =>
        games[c.subGameType]?.title
          ?.toLocaleLowerCase()
          .includes(search.toLocaleLowerCase()),
      );
    }

    // Filter out completed quests, considering both 'isCompleted' and 'winner'
    items = items.filter(quest => !quest.isCompleted && !quest.winner);

    return items;
  }, [quests, inactiveQuests, search, games, isInactive]);

  const handleShowMore = async () => {
    setIsLoading(true);
    try {
      await actions.loadInactiveQuests({
        before: inactiveQuests.at(-1)?.id,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isLoaded && !isInactive) {
      actions.loadQuests();
    }
  }, [actions, isLoaded, isInactive]);

  useEffect(() => {
    if (!isInactiveLoaded && isInactive) {
      actions.loadInactiveQuests();
    }
  }, [actions, isInactiveLoaded, isInactive]);

  const isPageLoaded =
    isSlotsLoaded && (isInactive ? isInactiveLoaded : isLoaded);

  const activeList = list.filter(
    quest =>
      quest.startDate &&
      quest.endDate &&
      Date.now() >= Number(quest.startDate) * 1000 &&
      Date.now() < Number(quest.endDate) * 1000,
  );

  const regularList = list.filter(quest => !quest.endDate);

  const resetTimeNZST = () => {
    const now = dayjs().tz('Australia/Sydney'); // Get current time in Auckland timezone
    let resetTime = now.hour(16).minute(0).second(0); // Set reset time to 5 PM today

    // If it's already past 5 PM, set reset time to 5 PM next day
    if (now.isAfter(resetTime)) {
      resetTime = resetTime.add(1, 'day');
    }

    return resetTime.valueOf();
  };

  return (
    <Content>
      <Flex
        flexDirection="column"
        gap="7"
        maxW="6xl"
        mx="auto"
        flexGrow="1"
        w="full"
        p="5"
      >
        {activeList.length > 0 && (
          <PreviewSlider
            title={t('quests.daily')}
            SlideComponent={QuestPreview}
            isLoaded={isPageLoaded}
            slides={activeList}
            titleRightContent={
              <Flex
                align="center"
                px="4"
                rounded="full"
                justifyContent="center"
                bg="sugar-dust"
                h="32px"
                color="candy-floss-text"
              >
                <Countdown date={resetTimeNZST()} />
              </Flex>
            }
          />
        )}
        <PreviewGrid
          // title={t('quests.regular')}
          Item={QuestPreview}
          isLoaded={isPageLoaded}
          list={regularList}
        />
        {isPageLoaded && !list.length && <EmptyResults icon={QuestsIcon} />}
        {isInactive && (
          <ShowMore
            current={list.length}
            total={total}
            onClick={handleShowMore}
            isLoading={isLoading}
          />
        )}
      </Flex>
    </Content>
  );
};
