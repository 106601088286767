import { WS_HANDLER_MESSAGE, WS_MESSAGE } from 'helpers/ws';
import { createReducer } from 'store';

import { WALLET_HANDLER_ID } from 'constants/general';
import { selectNft } from 'services/nft/modules/nft';
import {
  activateBonus,
  cancelSession,
  changeChatColor,
  changeDepositStatus,
  changeEmail,
  changeUserBadge,
  deactivateBonus,
  enable2fa,
  // generate2faRecoveryCodes,
  get2faInfo,
  loadAffiliateCampaignDetails,
  loadSessionsList,
  loadSlimUser,
  loadUser,
  loadUserBadge,
  loadUserBadges,
  loadUserNfts,
  loadUserStats,
  redeemRakeback,
  removeSocial,
  removeUserBadges,
  reset2fa,
  saveSettings,
  selfExclude,
  setUseLevelOverNft,
  shareBigWins,
  startFreeSpin,
  transferBonus,
  userWebsocket,
  verifySocial,
} from './actions';
import { UserType } from './types';

export const initialState: UserType = {
  isLoaded: false,
  isAuthorized: false,
  isAuthorizedReady: false,
  online: 0,
  profile: null,
  twoFA: null,
  recoveryCodes: [],
  twoFALoading: false,
  settingsLoading: {},
  viewUserIsLoading: false,
  viewUser: null,
  viewUserStats: {},
  bonusSession: null,
  sessions: [],
  social: {},
  userNfts: {},
  userBadge: null,
  userBadges: [],
  depositStatus: null,
  affiliateCampaignDetails: null,
  isAffiliateCampaignDetailsLoaded: false,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleBonusUpdate = (state: UserType, { payload }: { payload: any }) => {
  if (state.profile?.bonusBalances?.length) {
    const index = state.profile.bonusBalances.findIndex(
      item => item.id === payload.id || item.id === payload.depositReloadId,
    );

    if (index !== -1) {
      state.profile.bonusBalances[index] = {
        ...state.profile.bonusBalances[index],
        ...payload,
      };
    }
  }
};

export const reducer = createReducer(initialState, {
  [loadUser.toString()]: (state, { payload }) => {
    state.isLoaded = true;
    if (payload) {
      state.isAuthorizedReady = true;
      state.isAuthorized = true;
      state.profile = payload;
      // state.profile.settingFlags = state.profile.settingFlags;
      // state.profile.kycVerifiedFlags = bigInt(state.profile.kycVerifiedFlags);
      // state.profile.kycRequiredFlags = bigInt(state.profile.kycRequiredFlags);
      // state.profile.kycPendingFlags = bigInt(state.profile.kycPendingFlags);
      // state.profile.kycDeniedFlags = bigInt(state.profile.kycDeniedFlags);
      // state.profile.accountFlags = state.profile.accountFlags;
      // state.profile.permissionFlags = state.profile.permissionFlags;
      state.affiliateCampaignDetails =
        state.profile?.reloadsInfo?.welcomePackageCampaignDetail;
      state.isAffiliateCampaignDetailsLoaded = true;
    } else {
      state.isAuthorized = false;
      state.profile = null;
      state.isAuthorizedReady = true;
    }
  },

  [loadSlimUser.toString()]: (state, { payload, meta: { loading } }) => {
    state.viewUserIsLoading = loading;
    if (loading) {
      state.viewUser = null;
    }
    if (payload) {
      state.viewUser = payload;
    }
  },

  [loadUserStats.toString()]: (state, { payload, meta: { username } }) => {
    state.viewUserStats[username] = payload;
  },

  [changeEmail.toString()]: (state, { payload }) => {
    if (state.profile) {
      state.profile.email = payload;
      state.profile.emailConfirmed = false;
    }
  },

  [get2faInfo.toString()]: (state, { payload, meta: { loading } }) => {
    state.twoFALoading = loading;
    if (payload) {
      state.twoFA = payload;
    }
  },

  [enable2fa.toString()]: (state, { payload, meta: { loading } }) => {
    state.twoFALoading = loading;
    if (payload && state.twoFA) {
      state.twoFA.is2faEnabled = true;
      state.twoFA.hasAuthenticator = true;
      state.recoveryCodes = payload.recoveryCodes;
    }
  },

  [reset2fa.toString()]: (state, { payload, meta: { loading } }) => {
    state.twoFALoading = loading;
    if (payload && state.twoFA) {
      state.twoFA.is2faEnabled = false;
      state.twoFA.hasAuthenticator = false;
      state.recoveryCodes = [];
    }
  },

  // [generate2faRecoveryCodes]: (state, { payload }) => {
  //   state.recoveryCodes = payload.recoveryCodes;
  // },

  [saveSettings.toString()]: (
    state,
    { payload, meta: { settings, loading } },
  ) => {
    const settingIndices = Object.keys(settings);
    settingIndices.forEach(x => {
      state.settingsLoading[x] = loading;
    });
    if (payload && state.profile) {
      state.profile.settingFlags = payload;
    }
  },

  [setUseLevelOverNft.toString()]: (state, { meta: { value } }) => {
    if (state.profile) {
      state.profile.useLevelOverNFT = value;
    }
  },

  [WS_MESSAGE(userWebsocket, 'kyc-status-update')]: (state, { payload }) => {
    if (state.profile) {
      state.profile.kycStatusFlags = payload;
    }
  },

  [WS_MESSAGE(userWebsocket, 'ONLINE')]: (state, { payload }) => {
    state.online = payload;
  },

  [WS_MESSAGE(userWebsocket, 'SETTINGS')]: (state, { payload }) => {
    if (state.profile != null) {
      state.profile.settingFlags = payload;
    }
  },

  [WS_MESSAGE(userWebsocket, 'LEVEL')]: (state, { payload }) => {
    if (state.profile !== null) {
      state.profile.levelInfo = payload.level;
      state.profile.rakebackInfo = payload.rakebackInfo;

      if (payload.reloadsInfo) {
        state.profile.reloadsInfo = payload.reloadsInfo;
      }
    }
  },
  [WS_MESSAGE(userWebsocket, 'BONUS-BALANCE-NEW')]: (state, { payload }) => {
    state.profile?.bonusBalances.push(payload);
  },
  [WS_MESSAGE(userWebsocket, 'bonus-session-activate')]: handleBonusUpdate,
  [WS_MESSAGE(userWebsocket, 'bonus-session-start')]: handleBonusUpdate,
  [WS_MESSAGE(userWebsocket, 'bonus-session-pause')]: handleBonusUpdate,
  [WS_MESSAGE(userWebsocket, 'bonus-session-resume')]: handleBonusUpdate,
  [WS_MESSAGE(userWebsocket, 'bonus-session-stop')]: handleBonusUpdate,
  [WS_MESSAGE(userWebsocket, 'bonus-session-cancel')]: handleBonusUpdate,
  [WS_MESSAGE(userWebsocket, 'bonus-session-claim')]: handleBonusUpdate,
  [WS_MESSAGE(userWebsocket, 'bonus-session-transfer')]: handleBonusUpdate,
  // [WS_MESSAGE(userWebsocket, 'bonus-balance-new')]: handleBonusUpdate,
  [WS_MESSAGE(userWebsocket, 'INVENTORY')]: (state, { payload }) => {
    if (state.profile !== null) {
      state.profile = {
        ...state.profile,
        credit: payload.credit,
        rakebackCredit: payload.rakebackCredit,
        creditOnBonus: payload.creditOnBonus,
        currentWagerAmountForBonusWithdraw:
          payload.currentWagerAmountForBonusWithdraw,
        totalWagerAmountRequiredOnBonus:
          payload.totalWagerAmountRequiredOnBonus,
        activeBalanceType: payload.activeBalanceType,
      };

      // if (payload.activeBalanceType !== undefined) {
      //   state.profile.activeBalanceType = payload.activeBalanceType;
      // }

      // if (payload.reloadedAmount > 0) {
      //   state.profile.reloadsInfo = null;
      // }
    }

    if (payload.creditChange > 0 && payload.handlerId === WALLET_HANDLER_ID) {
      state.depositStatus = null;
    }
  },

  [selectNft.toString()]: (
    state,
    {
      payload: {
        payload: { icon, rakebackInfo },
      },
    },
  ) => {
    if (state.profile) {
      state.profile.icon = icon;
      state.profile.rakebackInfo = rakebackInfo;
    }
  },
  [shareBigWins.toString()]: (state, { meta: { value } }) => {
    if (state.profile) {
      state.profile.winMultiplierForShare = value;
    }
  },
  [transferBonus.toString()]: (state, { meta: { bonusBalanceId } }) => {
    if (state.profile?.bonusBalances?.length) {
      state.profile.bonusBalances = state.profile.bonusBalances.filter(
        item => item.id !== bonusBalanceId,
      );
    }
  },
  [selfExclude.toString()]: state => {
    if (state.profile) {
      state.profile.isSelfExcluded = true;
      state.profile.selfExcludeEndDate = Math.floor(Date.now() / 1000);
    }
  },
  [verifySocial.toString()]: (state, { payload, meta: { social } }) => {
    state.social[social] = payload;
  },
  [removeSocial.toString()]: (state, { meta: { social } }) => {
    state.social[social] = null;
  },
  [loadSessionsList.toString()]: (state, { payload }) => {
    state.sessions = payload;
  },
  [cancelSession.toString()]: (state, { meta: { id } }) => {
    state.sessions = state.sessions.filter(session => session.id !== id);
  },
  [changeChatColor.toString()]: (state, { meta: { color, loading } }) => {
    if (loading && state.profile) {
      state.profile.chatColor = color;
    }
  },
  [loadUserNfts.toString()]: (state, { meta: { username }, payload }) => {
    state.userNfts[username] = payload.nfts;
  },
  [changeUserBadge.toString()]: (state, { meta: { badge } }) => {
    state.userBadge = badge;
  },
  [removeUserBadges.toString()]: state => {
    state.userBadge = null;
  },
  [loadUserBadge.toString()]: (state, { payload }) => {
    state.userBadge = payload?.[0]?.name || null;
  },
  [loadUserBadges.toString()]: (state, { payload }) => {
    state.userBadges = payload || [];
  },
  [WS_HANDLER_MESSAGE(userWebsocket, 'WALLETSERVICE', 'DEPOSIT')]: (
    state,
    { payload },
  ) => {
    if (payload.status === 'CONFIRMING') {
      state.depositStatus = 'CONFIRMING';
    }
  },
  [changeDepositStatus.toString()]: (state, { payload }) => {
    state.depositStatus = payload;
  },
  [startFreeSpin.toString()]: (state, { meta: { id } }) => {
    if (state.profile) {
      const freeSpin = state.profile.freespins.find(spin => spin.id === id);

      if (freeSpin) {
        freeSpin.status = 'active';
      }
    }
  },
  [loadAffiliateCampaignDetails.toString()]: (state, { payload }) => {
    state.affiliateCampaignDetails = payload;
    state.isAffiliateCampaignDetailsLoaded = true;
  },
  [activateBonus.toString()]: (
    state,
    { meta: { loading, bonusBalanceId } },
  ) => {
    if (loading && state.profile) {
      const bonus = state.profile.bonusBalances.find(
        b => b.id === bonusBalanceId,
      );
      if (bonus) {
        bonus.status = 1;
      }
    }
  },
  [deactivateBonus.toString()]: (
    state,
    { meta: { loading, bonusBalanceId } },
  ) => {
    if (loading && state.profile) {
      const bonus = state.profile.bonusBalances.find(
        b => b.id === bonusBalanceId,
      );
      if (bonus) {
        bonus.status = 0;
      }
    }
  },
  [redeemRakeback.toString()]: state => {
    if (state.profile) {
      state.profile.rakebackCredit = 0;
    }
  },
});
